import * as React from 'react';
function SvgUndo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M13.669 6.579a10.004 10.004 0 016.682.353.833.833 0 10.639-1.539A11.667 11.667 0 006.884 9.576a11.67 11.67 0 00.043 13.251H3.999a.833.833 0 000 1.666h4.608a.792.792 0 00.154 0h.237c.46 0 .833-.373.833-.833v-5a.833.833 0 00-1.666 0v3.023a10 10 0 01.094-11.166 10.005 10.005 0 015.409-3.938zm11.164 7.081v-3.026a9.999 9.999 0 01-.084 11.177 9.997 9.997 0 01-12.1 3.582.834.834 0 10-.641 1.539 11.666 11.666 0 0014.117-4.18 11.669 11.669 0 00-.051-13.259h2.927a.833.833 0 000-1.666h-4.613a.914.914 0 00-.148 0h-.239a.833.833 0 00-.833.833v5a.833.833 0 001.666 0z" />
    </svg>
  );
}
export default SvgUndo;
