import { FC } from 'react';
import styles from './pagination.module.scss';
import classNames from 'classnames';

export type DigandoPageButtonProps = {
  page: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export const PageButton: FC<DigandoPageButtonProps> = ((props) => {
  const { page, currentPage, setCurrentPage } = props;

  return (
    <div
      className={classNames(styles.pageButton, { [styles.current]: currentPage === page })}
      key={page}
      onClick={() => setCurrentPage(page)}>
      {page}
    </div>
  );
});
