import { FC } from 'react';
import styles from './tag.module.scss';
import { Icon } from '../../atoms';

export interface ITagProps {
  text: string;
  tagKey: string;
  onClick: () => void;
}

export const Tag: FC<ITagProps> = (props) => {
  const { text, onClick } = props;

  return (
    <div
      className={styles.tag}
      onClick={() => {
        onClick();
      }}
    >
      <span>{text}</span>
      <Icon icon={'close'} size={'17px'} color={'white'} />
    </div>
  );
};
