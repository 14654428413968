import * as React from 'react';
function SvgWhatsapp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M7.104 7.02a13.299 13.299 0 019.408-3.854 13.301 13.301 0 0113.322 13.229v.011a13.339 13.339 0 01-7.055 11.659 13.35 13.35 0 01-13.275-.399l-4.429 1.405a.834.834 0 01-1.042-1.061l1.42-4.209a13.053 13.053 0 01-2.286-7.404v-.003a13.302 13.302 0 013.937-9.375zM16.511 4l-.003.833a11.632 11.632 0 00-11.675 11.57 11.39 11.39 0 002.216 6.77.833.833 0 01.118.76l-1.03 3.053 3.233-1.025a.83.83 0 01.709.098 11.67 11.67 0 0011.914.537 11.676 11.676 0 006.173-10.198A11.633 11.633 0 0016.512 4.833L16.51 4zm-3.115 5.822c.291.151.602.403.77.792.08.185.191.457.309.745.088.214.179.438.264.64.103.247.199.471.278.641a5.801 5.801 0 00.104.213.826.826 0 01.065.101 1.423 1.423 0 01.008 1.352 1.8 1.8 0 01-.154.258 1.639 1.639 0 01-.206.229l-.098.09a8.497 8.497 0 00-.25.233 9.394 9.394 0 003.968 3.365c.159-.206.371-.505.519-.738.2-.315.508-.582.946-.632.342-.039.646.078.782.13l.009.003c.282.108 1.531.716 2.073.98l.212.103.104.049c.112.053.24.114.34.169.121.067.375.213.536.49a.839.839 0 01.096.248 2.41 2.41 0 01-.286 2.104c-1.108 1.925-3.022 2.537-4.983 2.335-1.936-.2-3.998-1.186-5.708-2.542s-3.164-3.159-3.793-5.095c-.642-1.977-.414-4.1 1.267-5.876.513-.559 1.3-.693 1.887-.646.316.025.646.106.941.259zm9.126 10.126l-.027-.013-.103-.049-.121-.058-.285-.138c-.501-.243-1.387-.674-1.748-.836a9.741 9.741 0 01-.684.913 1.31 1.31 0 01-.82.452c-.341.042-.641-.065-.863-.174a11.055 11.055 0 01-4.956-4.294l-.011-.018c-.207-.353-.257-.748-.108-1.121.103-.258.296-.457.349-.511l.011-.011.021-.022c.223-.223.379-.364.464-.441l.001-.001-.005-.009a4.827 4.827 0 01-.135-.277 24.359 24.359 0 01-.302-.696c-.097-.232-.192-.464-.28-.678-.102-.249-.194-.475-.268-.648a.83.83 0 00-.33-.09 1.036 1.036 0 00-.37.034c-.107.032-.149.069-.157.079l-.012.014c-1.203 1.267-1.381 2.729-.896 4.22.499 1.535 1.706 3.085 3.243 4.303s3.308 2.031 4.844 2.19c1.5.155 2.685-.304 3.38-1.531a.703.703 0 01.043-.068.743.743 0 00.127-.518zm-9.854-8.621l-.002-.002.002.002zm2.461 1.541l-.002-.003a.012.012 0 00.002.003z" />
    </svg>
  );
}
export default SvgWhatsapp;
