import * as React from 'react';
function SvgSafe(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M15.405 6.167h.013a9.587 9.587 0 019.415 9.57v3.749H6.5a1.667 1.667 0 11.001-3.333h10a.833.833 0 000-1.666h-10c-.19 0-.378.016-.562.048a7.95 7.95 0 017.646-5.881 7.935 7.935 0 016.447 3.338.833.833 0 101.359-.965 9.588 9.588 0 00-7.401-4.03l.874-.655a.847.847 0 01.542-.173zM26.5 19.486v-3.751A11.253 11.253 0 0015.454 4.501a2.512 2.512 0 00-1.599.512l-3.292 2.469a9.611 9.611 0 00-6.508 8.071 3.334 3.334 0 002.444 5.599v3.333a3.333 3.333 0 106.666 0h7.5a4.167 4.167 0 003.333-1.667l1.25-1.667h3.751a.833.833 0 000-1.666h-2.5zm-3.333 1.667l-.5.667a2.491 2.491 0 01-2 1h-8.333a.833.833 0 00-.833.833v.833a1.663 1.663 0 01-2.844 1.179 1.667 1.667 0 01-.488-1.179v-3.333h15z" />
    </svg>
  );
}
export default SvgSafe;
