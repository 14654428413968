import { FC, createContext, useContext } from "react";
import { selectedAccessoriesManuallyState, selectedAccessoriesState } from '../../store/recoil/product-configurator';
import { useRecoilState } from "recoil";

type AccessoryConfiguratorContextProps = {
  resetSelectedAccessories: (accessories: AccessoryConfiguratorContextType[]) => void;
  setPreselectedAccessories: (accessories: (AccessoryConfiguratorContextType & { isPreselected: boolean })[]) => void;
  selectAccessory: (accessory: AccessoryConfiguratorContextType, quantity: number) => void;
  deselectAccessory: (accessory: AccessoryConfiguratorContextType) => void;
  switchAccessory: (accessory: AccessoryConfiguratorContextType) => void;
};

type AccessoryConfiguratorWrapperProps = {
  children: React.ReactNode;
};

const AccessoryConfiguratorContext = createContext<AccessoryConfiguratorContextProps>({
  resetSelectedAccessories: () => console.error('no AccessoryConfiguratorContextProvider'),
  setPreselectedAccessories: () => console.error('no AccessoryConfiguratorContextProvider'),
  selectAccessory: () => console.error('no AccessoryConfiguratorContextProvider'),
  deselectAccessory: () => console.error('no AccessoryConfiguratorContextProvider'),
  switchAccessory: () => console.error('no AccessoryConfiguratorContextProvider'),
});

export type AccessoryConfiguratorContextType = {
  accessoryType: {
    identifier: string;
  };
  title: string;
  priceType: string;
}

export const AccessoryConfiguratorWrapper: FC<AccessoryConfiguratorWrapperProps> = ({children}) => {
  const [selectedAccessories, setSelectedAccessories] = useRecoilState(selectedAccessoriesState);
  const [selectedAccessoriesManually, setSelectedAccessoriesManually] = useRecoilState(selectedAccessoriesManuallyState);

  /**
   * Resets selected accessories
   */
  const resetSelectedAccessories = (accessories:  AccessoryConfiguratorContextType[]) => {
    const accessoriesIds = accessories.map(accessory => accessory.accessoryType.identifier);

    const selected = selectedAccessories.filter((accessory) => {
      return accessoriesIds.includes(accessory.identifier);
    });

    setSelectedAccessories(selected);
    setSelectedAccessoriesManually(false);
  }

  /**
   * Sets preselected accessories
   */
  const setPreselectedAccessories = (accessories: (AccessoryConfiguratorContextType & { isPreselected: boolean })[]) => {
    if (true === selectedAccessoriesManually) {
      return;
    }

    const selectedAccessories = accessories
      .filter(accessory => accessory.isPreselected)
      .map(accessory => {
        return {
          identifier: accessory.accessoryType.identifier,
          quantity: 1,
        };
      });

    setSelectedAccessories(selectedAccessories);
  }

  /**
   * Switches selection of an accessory
   */
  const switchAccessory = (accessory: AccessoryConfiguratorContextType) => {
    if (-1 === selectedAccessories.findIndex(selectedAccessory => selectedAccessory.identifier === accessory.accessoryType.identifier)) {
      selectAccessory(accessory, 1);
    } else {
      deselectAccessory(accessory);
    }
  };

  /**
   * Deselects an accessory
   */
  const deselectAccessory = (accessory: AccessoryConfiguratorContextType) => {
    setSelectedAccessoriesManually(true);

    const selected = selectedAccessories.filter(selectedAccessory => {
      return selectedAccessory.identifier !== accessory.accessoryType.identifier;
    });

    setSelectedAccessories(selected);
  }

  /**
   * Selects an accessory
   */
  const selectAccessory = (accessory: AccessoryConfiguratorContextType, quantity: number) => {
    const accessoryId = accessory.accessoryType.identifier;

    setSelectedAccessoriesManually(true);

    if (-1 === selectedAccessories.findIndex(selectedAccessory => selectedAccessory.identifier === accessory.accessoryType.identifier)) {
      setSelectedAccessories([...selectedAccessories, {
        identifier: accessoryId,
        quantity,
      }]);
    } else {
      updateAccessoryQuantity(accessory, quantity);
    }
  }

  const updateAccessoryQuantity = (accessory: AccessoryConfiguratorContextType, quantity: number) => {
    const selected = selectedAccessories.map(selectedAccessory => {
      if (selectedAccessory.identifier === accessory.accessoryType.identifier) {
        return {
          identifier: selectedAccessory.identifier,
          quantity,
        };
      }

      return selectedAccessory;
    });

    setSelectedAccessories(selected);
  }

  return (
    <AccessoryConfiguratorContext.Provider value={{
      resetSelectedAccessories,
      setPreselectedAccessories,
      selectAccessory,
      deselectAccessory,
      switchAccessory,
    }}>
      {children}
    </AccessoryConfiguratorContext.Provider>
  );
}

export function useConfiguratorAccessoryContext() {
  return useContext(AccessoryConfiguratorContext);
}
