import * as React from 'react';
function SvgCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M29.333 16c0 7.364-5.969 13.333-13.333 13.333S2.667 23.364 2.667 16 8.637 2.667 16 2.667 29.333 8.637 29.333 16zm-14.875 7.06l9.893-9.892a.86.86 0 000-1.216l-1.217-1.216a.86.86 0 00-1.217 0l-8.068 8.068-3.767-3.767a.86.86 0 00-1.217 0l-1.216 1.217a.86.86 0 000 1.216l5.591 5.591a.86.86 0 001.217 0z" />
    </svg>
  );
}
export default SvgCheck;
