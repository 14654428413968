import { FC, FocusEvent, useState } from 'react';
import styles from './global-search-input.module.scss';
import { Icon } from '../atoms';
import { themeVariables } from '../foundation';
import classNames from 'classnames';

export interface IGlobalSearchInputProps {
  id: string;
  name: string;
  label: string;
  placeholder?: string;
  defaultValue?: string;
  isLoading?: boolean;
  onChange: (value: string) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onClick?: () => void;
  onDelete?: () => void;
  value?: string;
  type?: string;
  min?: string;
  compact?: boolean;
}

export const GlobalSearchInput: FC<IGlobalSearchInputProps> = (props) => {
  const {
    name,
    label,
    defaultValue = '',
    id,
    placeholder = '',
    onChange,
    onFocus,
    onBlur,
    onClick,
    onDelete,
    value,
    type = 'text',
    min,
    compact = false,
  } = props;

  const [internalValue, setInternalValue] = useState<string>(defaultValue);

  return (
    <div className={classNames({
      [styles.formElement]: true,
      [styles.compact]: compact,
    })}>
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>

      <input
        className={classNames({
          [styles.input]: true,
        })}
        type={type}
        id={id}
        name={name}
        value={value || internalValue}
        placeholder={placeholder}
        autoComplete={'off'}
        onChange={(event): void => {
          const newValue = event.target.value;

          onChange(newValue);

          if (value !== undefined) {
            return;
          }

          setInternalValue(newValue);
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        min={min}
      />

      {(value !== '' || internalValue !== '') && onDelete && (
        <span
          className={styles.deleteIcon}
          onClick={() => {
            setInternalValue('');
            if (onDelete) {
              onDelete();
            }
          }}
        >
              {<Icon icon={'close'} color={themeVariables.black} />}
            </span>
      )}
    </div>
  );
};
