import { ButtonHTMLAttributes, FC, useEffect, useState } from 'react';
import styles from './button.module.scss';
import classNames from 'classnames';
import { Loader } from '../atoms';
import Link from 'next/link';
import { UrlObject } from 'url';

export interface IDigandoStylingButtonProps {
  /**
   * The left and right padding of the button
   * default is with a bigger padding
   * small is with a smaller padding, consider that the loading state can not be used with small padding
   */
  spacing?: 'small' | 'default';
  /**
   * If the button should be disabled
   * HTML click on button will not work but is not validated by the component
   */
  disabled?: boolean;
  /**
   * If the button should be in loading state
   */
  loading?: boolean;
  /**
   * A smaller button with smaller font size and padding
   */
  small?: boolean;
  /**
   * A button with a border and a light background color
   */
  bordered?: boolean;
  /**
   * The button will increase to the full width of the parent container
   */
  fullWidth?: boolean;
  /**
   * A button with a transparent background and a border
   * @todo: implement and add figma design
   */
  ghost?: boolean;
  /**
   * If the button should be a link
   * @deprecated Use the Link Button component instead <Button> --> <LinkButton>
   */
  href?: UrlObject;
  /**
   * If the button text should be uppercase
   */
  uppercase?: boolean;
}

export interface IDigandoButtonProps extends IDigandoStylingButtonProps {
  type?: ButtonHTMLAttributes<void>['type'];
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onFocus?: () => void;
}

let loaderTimeout: NodeJS.Timeout | null = null;

export const Button: FC<IDigandoButtonProps> = (props) => {
  const {
    onClick,
    onFocus,
    type = 'submit',
    spacing = 'default',
    small = false,
    bordered = false,
    disabled = false,
    loading = false,
    fullWidth = false,
    ghost = false,
    href,
    uppercase = true,
  } = props;

  const [showLoader, setShowLoader] = useState(loading);

  useEffect(() => {
    if (loading) {
      setShowLoader(true);
    } else {
      loaderTimeout = setTimeout(() => {
        setShowLoader(false);
      }, 300);
    }

    return () => {
      if (loaderTimeout) {
        clearTimeout(loaderTimeout);
      }
    };
  }, [loading]);

  if (href) {
    return (
      <Link
        href={href}
        className={classNames(styles.button, {
          [styles.bordered]: bordered,
          [styles.small]: small,
          [styles.fullWidth]: fullWidth,
          [styles.spacingSmall]: spacing === 'small',
          [styles.loading]: loading,
          [styles.ghost]: ghost,
        })}
      >
        <span className={classNames({
          [styles.uppercase]: uppercase,
        })}>{props.children}</span>
        <span className={styles.buttonLoader}>{showLoader && <Loader invert={!bordered} />}</span>
      </Link>
    );
  }

  return (
    <button
      className={classNames(styles.button, {
        [styles.bordered]: bordered,
        [styles.small]: small,
        [styles.fullWidth]: fullWidth,
        [styles.spacingSmall]: spacing === 'small',
        [styles.loading]: loading,
        [styles.ghost]: ghost,
      })}
      type={type}
      onClick={onClick}
      onFocus={onFocus}
      disabled={disabled}
    >
        <span className={classNames({
          [styles.buttonText]: true,
          [styles.uppercase]: uppercase,
        })}>
          {props.children}
        </span>
      {showLoader && (
        <span className={styles.buttonLoader}>
          <Loader
            invert={!bordered}
            size={20}
          />
        </span>
      )}
    </button>
  );
};
