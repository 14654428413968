import * as React from 'react';
function SvgMinus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M25.143 13.333H6.857c-.841 0-1.524.651-1.524 1.455v1.455c0 .803.682 1.455 1.524 1.455h18.286c.841 0 1.524-.651 1.524-1.455v-1.455c0-.803-.682-1.455-1.524-1.455z" />
    </svg>
  );
}
export default SvgMinus;
