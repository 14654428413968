import * as React from 'react';
function SvgUser(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M26.778 29.333a.833.833 0 01-.833-.833c0-2.43-.901-4.787-2.501-6.604V28.5a.833.833 0 01-1.666 0v-8.118a9.928 9.928 0 00-.857-.552c-.25 2.524-2.385 4.503-4.975 4.503s-4.726-1.979-4.976-4.503c-.296.17-.583.356-.859.554V28.5a.833.833 0 01-1.666 0v-6.602A10.03 10.03 0 005.946 28.5a.833.833 0 01-1.666 0c0-3.563 1.603-6.886 4.401-9.118a.776.776 0 01.178-.139 11.658 11.658 0 012.65-1.53 11.529 11.529 0 014.439-.88 11.593 11.593 0 017.096 2.415.883.883 0 01.16.125 11.612 11.612 0 014.41 9.126.835.835 0 01-.834.833zm-14.167-10c0 1.838 1.496 3.333 3.333 3.333s3.333-1.496 3.333-3.333v-.259c-1.073-.381-2.192-.574-3.333-.574s-2.261.193-3.333.574v.259zM15.943 16c-3.367 0-6.199-2.532-6.613-5.833h-.887a.833.833 0 010-1.666h.887a6.684 6.684 0 013.809-5.213c.884-.411 1.828-.62 2.804-.62a6.598 6.598 0 012.893.662 6.672 6.672 0 013.72 5.171h.887a.833.833 0 010 1.666h-.887A6.682 6.682 0 0115.943 16zm-4.925-5.833c.401 2.37 2.482 4.167 4.926 4.167s4.524-1.797 4.926-4.167h-9.851zm9.85-1.667a5.027 5.027 0 00-1.591-2.882V8.5h1.591zm-3.258 0V4.62a4.965 4.965 0 00-3.334 0V8.5h3.333zm-5 0V5.618A5.027 5.027 0 0011.019 8.5h1.591z" />
    </svg>
  );
}
export default SvgUser;
