import * as React from 'react';
function SvgCalendar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M5.167 29.333a2.502 2.502 0 01-2.5-2.5V8.5c0-1.379 1.121-2.5 2.5-2.5h4.167V3.5A.833.833 0 0111 3.5V6h10V3.5a.833.833 0 011.666 0V6h4.167c1.379 0 2.5 1.121 2.5 2.5v18.333c0 1.379-1.121 2.5-2.5 2.5H5.166zm0-21.666a.833.833 0 00-.833.833v18.333c0 .46.373.833.833.833h21.667c.46 0 .833-.373.833-.833V8.5a.833.833 0 00-.833-.833h-4.167v1.667a.833.833 0 01-1.666 0V7.667h-10v1.667a.833.833 0 01-1.666 0V7.667H5.168z" />
      <path d="M8.5 24.333a.833.833 0 01-.833-.833v-5c0-.46.373-.833.833-.833h4.167V13.5c0-.46.373-.833.833-.833h10c.46 0 .833.373.833.833v10c0 .46-.373.833-.833.833h-15zm14.167-1.666v-3.333h-3.333v3.333h3.333zm-5 0v-3.333h-3.333v3.333h3.333zm-5 0v-3.333H9.334v3.333h3.333zm10-5v-3.333h-3.333v3.333h3.333zm-5 0v-3.333h-3.333v3.333h3.333z" />
    </svg>
  );
}
export default SvgCalendar;
