import * as Types from './graphql';

import * as Operations from './graphql';
import { NextRouter, useRouter } from 'next/router'
import { QueryHookOptions, useQuery } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type React from 'react';
import { getApolloClient } from '../../lib/apollo-client/apollo-client';
export async function getServerPageBasket
    (options: Omit<Apollo.QueryOptions<Types.BasketPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BasketPageQuery>({ ...options, query: Operations.BasketPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBasket = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BasketPageQuery, Types.BasketPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.BasketPageQuery, Types.BasketPageQueryVariables>(Operations.BasketPageDocument, options);
};
export type PageBasketComp = React.FC<{data?: Types.BasketPageQuery, error?: Apollo.ApolloError}>;
export const ssrBasket = {
      getServerPage: getServerPageBasket,
      
      usePage: useBasket,
    }
export async function getServerPageBlogCategories
    (options: Omit<Apollo.QueryOptions<Types.BlogCategoriesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BlogCategoriesQuery>({ ...options, query: Operations.BlogCategoriesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBlogCategories = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BlogCategoriesQuery, Types.BlogCategoriesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.BlogCategoriesQuery, Types.BlogCategoriesQueryVariables>(Operations.BlogCategoriesDocument, options);
};
export type PageBlogCategoriesComp = React.FC<{data?: Types.BlogCategoriesQuery, error?: Apollo.ApolloError}>;
export const ssrBlogCategories = {
      getServerPage: getServerPageBlogCategories,
      
      usePage: useBlogCategories,
    }
export async function getServerPageBlogOverviewHeader
    (options: Omit<Apollo.QueryOptions<Types.BlogOverviewHeaderQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BlogOverviewHeaderQuery>({ ...options, query: Operations.BlogOverviewHeaderDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBlogOverviewHeader = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BlogOverviewHeaderQuery, Types.BlogOverviewHeaderQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.BlogOverviewHeaderQuery, Types.BlogOverviewHeaderQueryVariables>(Operations.BlogOverviewHeaderDocument, options);
};
export type PageBlogOverviewHeaderComp = React.FC<{data?: Types.BlogOverviewHeaderQuery, error?: Apollo.ApolloError}>;
export const ssrBlogOverviewHeader = {
      getServerPage: getServerPageBlogOverviewHeader,
      
      usePage: useBlogOverviewHeader,
    }
export async function getServerPageBlogOverviewNewPosts
    (options: Omit<Apollo.QueryOptions<Types.BlogOverviewNewPostsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BlogOverviewNewPostsQuery>({ ...options, query: Operations.BlogOverviewNewPostsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBlogOverviewNewPosts = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BlogOverviewNewPostsQuery, Types.BlogOverviewNewPostsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.BlogOverviewNewPostsQuery, Types.BlogOverviewNewPostsQueryVariables>(Operations.BlogOverviewNewPostsDocument, options);
};
export type PageBlogOverviewNewPostsComp = React.FC<{data?: Types.BlogOverviewNewPostsQuery, error?: Apollo.ApolloError}>;
export const ssrBlogOverviewNewPosts = {
      getServerPage: getServerPageBlogOverviewNewPosts,
      
      usePage: useBlogOverviewNewPosts,
    }
export async function getServerPageBlogOverview
    (options: Omit<Apollo.QueryOptions<Types.BlogOverviewQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BlogOverviewQuery>({ ...options, query: Operations.BlogOverviewDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBlogOverview = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BlogOverviewQuery, Types.BlogOverviewQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.BlogOverviewQuery, Types.BlogOverviewQueryVariables>(Operations.BlogOverviewDocument, options);
};
export type PageBlogOverviewComp = React.FC<{data?: Types.BlogOverviewQuery, error?: Apollo.ApolloError}>;
export const ssrBlogOverview = {
      getServerPage: getServerPageBlogOverview,
      
      usePage: useBlogOverview,
    }
export async function getServerPageBlog
    (options: Omit<Apollo.QueryOptions<Types.BlogPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BlogPageQuery>({ ...options, query: Operations.BlogPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBlog = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BlogPageQuery, Types.BlogPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.BlogPageQuery, Types.BlogPageQueryVariables>(Operations.BlogPageDocument, options);
};
export type PageBlogComp = React.FC<{data?: Types.BlogPageQuery, error?: Apollo.ApolloError}>;
export const ssrBlog = {
      getServerPage: getServerPageBlog,
      
      usePage: useBlog,
    }
export async function getServerPageConfigurator
    (options: Omit<Apollo.QueryOptions<Types.ConfiguratorPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ConfiguratorPageQuery>({ ...options, query: Operations.ConfiguratorPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useConfigurator = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ConfiguratorPageQuery, Types.ConfiguratorPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.ConfiguratorPageQuery, Types.ConfiguratorPageQueryVariables>(Operations.ConfiguratorPageDocument, options);
};
export type PageConfiguratorComp = React.FC<{data?: Types.ConfiguratorPageQuery, error?: Apollo.ApolloError}>;
export const ssrConfigurator = {
      getServerPage: getServerPageConfigurator,
      
      usePage: useConfigurator,
    }
export async function getServerPageError
    (options: Omit<Apollo.QueryOptions<Types.ErrorPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ErrorPageQuery>({ ...options, query: Operations.ErrorPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useError = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ErrorPageQuery, Types.ErrorPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.ErrorPageQuery, Types.ErrorPageQueryVariables>(Operations.ErrorPageDocument, options);
};
export type PageErrorComp = React.FC<{data?: Types.ErrorPageQuery, error?: Apollo.ApolloError}>;
export const ssrError = {
      getServerPage: getServerPageError,
      
      usePage: useError,
    }
export async function getServerPageHome
    (options: Omit<Apollo.QueryOptions<Types.HomepageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.HomepageQuery>({ ...options, query: Operations.HomepageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useHome = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.HomepageQuery, Types.HomepageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.HomepageQuery, Types.HomepageQueryVariables>(Operations.HomepageDocument, options);
};
export type PageHomeComp = React.FC<{data?: Types.HomepageQuery, error?: Apollo.ApolloError}>;
export const ssrHome = {
      getServerPage: getServerPageHome,
      
      usePage: useHome,
    }
export async function getServerPageGetJobOverview
    (options: Omit<Apollo.QueryOptions<Types.GetJobOverviewQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GetJobOverviewQuery>({ ...options, query: Operations.GetJobOverviewDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetJobOverview = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetJobOverviewQuery, Types.GetJobOverviewQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.GetJobOverviewQuery, Types.GetJobOverviewQueryVariables>(Operations.GetJobOverviewDocument, options);
};
export type PageGetJobOverviewComp = React.FC<{data?: Types.GetJobOverviewQuery, error?: Apollo.ApolloError}>;
export const ssrGetJobOverview = {
      getServerPage: getServerPageGetJobOverview,
      
      usePage: useGetJobOverview,
    }
export async function getServerPageJob
    (options: Omit<Apollo.QueryOptions<Types.JobQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.JobQuery>({ ...options, query: Operations.JobDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useJob = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.JobQuery, Types.JobQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.JobQuery, Types.JobQueryVariables>(Operations.JobDocument, options);
};
export type PageJobComp = React.FC<{data?: Types.JobQuery, error?: Apollo.ApolloError}>;
export const ssrJob = {
      getServerPage: getServerPageJob,
      
      usePage: useJob,
    }
export async function getServerPageGetLogin
    (options: Omit<Apollo.QueryOptions<Types.GetLoginPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GetLoginPageQuery>({ ...options, query: Operations.GetLoginPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetLogin = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetLoginPageQuery, Types.GetLoginPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.GetLoginPageQuery, Types.GetLoginPageQueryVariables>(Operations.GetLoginPageDocument, options);
};
export type PageGetLoginComp = React.FC<{data?: Types.GetLoginPageQuery, error?: Apollo.ApolloError}>;
export const ssrGetLogin = {
      getServerPage: getServerPageGetLogin,
      
      usePage: useGetLogin,
    }
export async function getServerPageGetNavigation
    (options: Omit<Apollo.QueryOptions<Types.GetNavigationQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GetNavigationQuery>({ ...options, query: Operations.GetNavigationDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetNavigation = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetNavigationQuery, Types.GetNavigationQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.GetNavigationQuery, Types.GetNavigationQueryVariables>(Operations.GetNavigationDocument, options);
};
export type PageGetNavigationComp = React.FC<{data?: Types.GetNavigationQuery, error?: Apollo.ApolloError}>;
export const ssrGetNavigation = {
      getServerPage: getServerPageGetNavigation,
      
      usePage: useGetNavigation,
    }
export async function getServerPageProduct
    (options: Omit<Apollo.QueryOptions<Types.ProductPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ProductPageQuery>({ ...options, query: Operations.ProductPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProduct = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProductPageQuery, Types.ProductPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.ProductPageQuery, Types.ProductPageQueryVariables>(Operations.ProductPageDocument, options);
};
export type PageProductComp = React.FC<{data?: Types.ProductPageQuery, error?: Apollo.ApolloError}>;
export const ssrProduct = {
      getServerPage: getServerPageProduct,
      
      usePage: useProduct,
    }
export async function getServerPageSearch
    (options: Omit<Apollo.QueryOptions<Types.SearchPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.SearchPageQuery>({ ...options, query: Operations.SearchPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useSearch = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SearchPageQuery, Types.SearchPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.SearchPageQuery, Types.SearchPageQueryVariables>(Operations.SearchPageDocument, options);
};
export type PageSearchComp = React.FC<{data?: Types.SearchPageQuery, error?: Apollo.ApolloError}>;
export const ssrSearch = {
      getServerPage: getServerPageSearch,
      
      usePage: useSearch,
    }
export async function getServerPageSitemap
    (options: Omit<Apollo.QueryOptions<Types.SitemapPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.SitemapPageQuery>({ ...options, query: Operations.SitemapPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useSitemap = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SitemapPageQuery, Types.SitemapPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.SitemapPageQuery, Types.SitemapPageQueryVariables>(Operations.SitemapPageDocument, options);
};
export type PageSitemapComp = React.FC<{data?: Types.SitemapPageQuery, error?: Apollo.ApolloError}>;
export const ssrSitemap = {
      getServerPage: getServerPageSitemap,
      
      usePage: useSitemap,
    }
export async function getServerPageStandard
    (options: Omit<Apollo.QueryOptions<Types.StandardPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.StandardPageQuery>({ ...options, query: Operations.StandardPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useStandard = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.StandardPageQuery, Types.StandardPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.StandardPageQuery, Types.StandardPageQueryVariables>(Operations.StandardPageDocument, options);
};
export type PageStandardComp = React.FC<{data?: Types.StandardPageQuery, error?: Apollo.ApolloError}>;
export const ssrStandard = {
      getServerPage: getServerPageStandard,
      
      usePage: useStandard,
    }
export async function getServerPageTenantMap
    (options: Omit<Apollo.QueryOptions<Types.TenantMapPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.TenantMapPageQuery>({ ...options, query: Operations.TenantMapPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useTenantMap = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.TenantMapPageQuery, Types.TenantMapPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.TenantMapPageQuery, Types.TenantMapPageQueryVariables>(Operations.TenantMapPageDocument, options);
};
export type PageTenantMapComp = React.FC<{data?: Types.TenantMapPageQuery, error?: Apollo.ApolloError}>;
export const ssrTenantMap = {
      getServerPage: getServerPageTenantMap,
      
      usePage: useTenantMap,
    }
export async function getServerPageTenant
    (options: Omit<Apollo.QueryOptions<Types.TenantPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.TenantPageQuery>({ ...options, query: Operations.TenantPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useTenant = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.TenantPageQuery, Types.TenantPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.TenantPageQuery, Types.TenantPageQueryVariables>(Operations.TenantPageDocument, options);
};
export type PageTenantComp = React.FC<{data?: Types.TenantPageQuery, error?: Apollo.ApolloError}>;
export const ssrTenant = {
      getServerPage: getServerPageTenant,
      
      usePage: useTenant,
    }
export async function getServerPageSearchById
    (options: Omit<Apollo.QueryOptions<Types.SearchPageByIdQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.SearchPageByIdQuery>({ ...options, query: Operations.SearchPageByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useSearchById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SearchPageByIdQuery, Types.SearchPageByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.SearchPageByIdQuery, Types.SearchPageByIdQueryVariables>(Operations.SearchPageByIdDocument, options);
};
export type PageSearchByIdComp = React.FC<{data?: Types.SearchPageByIdQuery, error?: Apollo.ApolloError}>;
export const ssrSearchById = {
      getServerPage: getServerPageSearchById,
      
      usePage: useSearchById,
    }
















export async function getServerPageAddressAutocomplete
    (options: Omit<Apollo.QueryOptions<Types.AddressAutocompleteQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.AddressAutocompleteQuery>({ ...options, query: Operations.AddressAutocompleteDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useAddressAutocomplete = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.AddressAutocompleteQuery, Types.AddressAutocompleteQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.AddressAutocompleteQuery, Types.AddressAutocompleteQueryVariables>(Operations.AddressAutocompleteDocument, options);
};
export type PageAddressAutocompleteComp = React.FC<{data?: Types.AddressAutocompleteQuery, error?: Apollo.ApolloError}>;
export const ssrAddressAutocomplete = {
      getServerPage: getServerPageAddressAutocomplete,
      
      usePage: useAddressAutocomplete,
    }
export async function getServerPageAuthProcessContent
    (options: Omit<Apollo.QueryOptions<Types.AuthProcessContentQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.AuthProcessContentQuery>({ ...options, query: Operations.AuthProcessContentDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useAuthProcessContent = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.AuthProcessContentQuery, Types.AuthProcessContentQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.AuthProcessContentQuery, Types.AuthProcessContentQueryVariables>(Operations.AuthProcessContentDocument, options);
};
export type PageAuthProcessContentComp = React.FC<{data?: Types.AuthProcessContentQuery, error?: Apollo.ApolloError}>;
export const ssrAuthProcessContent = {
      getServerPage: getServerPageAuthProcessContent,
      
      usePage: useAuthProcessContent,
    }
export async function getServerPageBasketData
    (options: Omit<Apollo.QueryOptions<Types.BasketDataQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BasketDataQuery>({ ...options, query: Operations.BasketDataDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBasketData = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BasketDataQuery, Types.BasketDataQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.BasketDataQuery, Types.BasketDataQueryVariables>(Operations.BasketDataDocument, options);
};
export type PageBasketDataComp = React.FC<{data?: Types.BasketDataQuery, error?: Apollo.ApolloError}>;
export const ssrBasketData = {
      getServerPage: getServerPageBasketData,
      
      usePage: useBasketData,
    }
export async function getServerPageBookedBookings
    (options: Omit<Apollo.QueryOptions<Types.BookedBookingsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BookedBookingsQuery>({ ...options, query: Operations.BookedBookingsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBookedBookings = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BookedBookingsQuery, Types.BookedBookingsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.BookedBookingsQuery, Types.BookedBookingsQueryVariables>(Operations.BookedBookingsDocument, options);
};
export type PageBookedBookingsComp = React.FC<{data?: Types.BookedBookingsQuery, error?: Apollo.ApolloError}>;
export const ssrBookedBookings = {
      getServerPage: getServerPageBookedBookings,
      
      usePage: useBookedBookings,
    }
export async function getServerPageBookingFilterOptions
    (options: Omit<Apollo.QueryOptions<Types.BookingFilterOptionsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BookingFilterOptionsQuery>({ ...options, query: Operations.BookingFilterOptionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBookingFilterOptions = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BookingFilterOptionsQuery, Types.BookingFilterOptionsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.BookingFilterOptionsQuery, Types.BookingFilterOptionsQueryVariables>(Operations.BookingFilterOptionsDocument, options);
};
export type PageBookingFilterOptionsComp = React.FC<{data?: Types.BookingFilterOptionsQuery, error?: Apollo.ApolloError}>;
export const ssrBookingFilterOptions = {
      getServerPage: getServerPageBookingFilterOptions,
      
      usePage: useBookingFilterOptions,
    }
export async function getServerPageGetBooking
    (options: Omit<Apollo.QueryOptions<Types.GetBookingQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GetBookingQuery>({ ...options, query: Operations.GetBookingDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetBooking = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetBookingQuery, Types.GetBookingQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.GetBookingQuery, Types.GetBookingQueryVariables>(Operations.GetBookingDocument, options);
};
export type PageGetBookingComp = React.FC<{data?: Types.GetBookingQuery, error?: Apollo.ApolloError}>;
export const ssrGetBooking = {
      getServerPage: getServerPageGetBooking,
      
      usePage: useGetBooking,
    }
export async function getServerPageBookingsAsCsv
    (options: Omit<Apollo.QueryOptions<Types.BookingsAsCsvQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BookingsAsCsvQuery>({ ...options, query: Operations.BookingsAsCsvDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBookingsAsCsv = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BookingsAsCsvQuery, Types.BookingsAsCsvQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.BookingsAsCsvQuery, Types.BookingsAsCsvQueryVariables>(Operations.BookingsAsCsvDocument, options);
};
export type PageBookingsAsCsvComp = React.FC<{data?: Types.BookingsAsCsvQuery, error?: Apollo.ApolloError}>;
export const ssrBookingsAsCsv = {
      getServerPage: getServerPageBookingsAsCsv,
      
      usePage: useBookingsAsCsv,
    }
export async function getServerPageCart
    (options: Omit<Apollo.QueryOptions<Types.CartQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CartQuery>({ ...options, query: Operations.CartDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCart = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CartQuery, Types.CartQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.CartQuery, Types.CartQueryVariables>(Operations.CartDocument, options);
};
export type PageCartComp = React.FC<{data?: Types.CartQuery, error?: Apollo.ApolloError}>;
export const ssrCart = {
      getServerPage: getServerPageCart,
      
      usePage: useCart,
    }
export async function getServerPageCatalogSearch
    (options: Omit<Apollo.QueryOptions<Types.CatalogSearchQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CatalogSearchQuery>({ ...options, query: Operations.CatalogSearchDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCatalogSearch = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CatalogSearchQuery, Types.CatalogSearchQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.CatalogSearchQuery, Types.CatalogSearchQueryVariables>(Operations.CatalogSearchDocument, options);
};
export type PageCatalogSearchComp = React.FC<{data?: Types.CatalogSearchQuery, error?: Apollo.ApolloError}>;
export const ssrCatalogSearch = {
      getServerPage: getServerPageCatalogSearch,
      
      usePage: useCatalogSearch,
    }
export async function getServerPageCategoryByText
    (options: Omit<Apollo.QueryOptions<Types.CategoryByTextQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CategoryByTextQuery>({ ...options, query: Operations.CategoryByTextDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCategoryByText = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CategoryByTextQuery, Types.CategoryByTextQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.CategoryByTextQuery, Types.CategoryByTextQueryVariables>(Operations.CategoryByTextDocument, options);
};
export type PageCategoryByTextComp = React.FC<{data?: Types.CategoryByTextQuery, error?: Apollo.ApolloError}>;
export const ssrCategoryByText = {
      getServerPage: getServerPageCategoryByText,
      
      usePage: useCategoryByText,
    }
export async function getServerPageCategoryByUriComponent
    (options: Omit<Apollo.QueryOptions<Types.CategoryByUriComponentQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CategoryByUriComponentQuery>({ ...options, query: Operations.CategoryByUriComponentDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCategoryByUriComponent = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CategoryByUriComponentQuery, Types.CategoryByUriComponentQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.CategoryByUriComponentQuery, Types.CategoryByUriComponentQueryVariables>(Operations.CategoryByUriComponentDocument, options);
};
export type PageCategoryByUriComponentComp = React.FC<{data?: Types.CategoryByUriComponentQuery, error?: Apollo.ApolloError}>;
export const ssrCategoryByUriComponent = {
      getServerPage: getServerPageCategoryByUriComponent,
      
      usePage: useCategoryByUriComponent,
    }
export async function getServerPageCategorySitemapVirtual
    (options: Omit<Apollo.QueryOptions<Types.CategorySitemapVirtualQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CategorySitemapVirtualQuery>({ ...options, query: Operations.CategorySitemapVirtualDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCategorySitemapVirtual = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CategorySitemapVirtualQuery, Types.CategorySitemapVirtualQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.CategorySitemapVirtualQuery, Types.CategorySitemapVirtualQueryVariables>(Operations.CategorySitemapVirtualDocument, options);
};
export type PageCategorySitemapVirtualComp = React.FC<{data?: Types.CategorySitemapVirtualQuery, error?: Apollo.ApolloError}>;
export const ssrCategorySitemapVirtual = {
      getServerPage: getServerPageCategorySitemapVirtual,
      
      usePage: useCategorySitemapVirtual,
    }
export async function getServerPageCategorySitemap
    (options: Omit<Apollo.QueryOptions<Types.CategorySitemapQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CategorySitemapQuery>({ ...options, query: Operations.CategorySitemapDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCategorySitemap = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CategorySitemapQuery, Types.CategorySitemapQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.CategorySitemapQuery, Types.CategorySitemapQueryVariables>(Operations.CategorySitemapDocument, options);
};
export type PageCategorySitemapComp = React.FC<{data?: Types.CategorySitemapQuery, error?: Apollo.ApolloError}>;
export const ssrCategorySitemap = {
      getServerPage: getServerPageCategorySitemap,
      
      usePage: useCategorySitemap,
    }
export async function getServerPageCountries
    (options: Omit<Apollo.QueryOptions<Types.CountriesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CountriesQuery>({ ...options, query: Operations.CountriesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCountries = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CountriesQuery, Types.CountriesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.CountriesQuery, Types.CountriesQueryVariables>(Operations.CountriesDocument, options);
};
export type PageCountriesComp = React.FC<{data?: Types.CountriesQuery, error?: Apollo.ApolloError}>;
export const ssrCountries = {
      getServerPage: getServerPageCountries,
      
      usePage: useCountries,
    }
export async function getServerPageDatoDocumentRelations
    (options: Omit<Apollo.QueryOptions<Types.DatoDocumentRelationsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.DatoDocumentRelationsQuery>({ ...options, query: Operations.DatoDocumentRelationsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useDatoDocumentRelations = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.DatoDocumentRelationsQuery, Types.DatoDocumentRelationsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.DatoDocumentRelationsQuery, Types.DatoDocumentRelationsQueryVariables>(Operations.DatoDocumentRelationsDocument, options);
};
export type PageDatoDocumentRelationsComp = React.FC<{data?: Types.DatoDocumentRelationsQuery, error?: Apollo.ApolloError}>;
export const ssrDatoDocumentRelations = {
      getServerPage: getServerPageDatoDocumentRelations,
      
      usePage: useDatoDocumentRelations,
    }
export async function getServerPageGeolocation
    (options: Omit<Apollo.QueryOptions<Types.GeolocationQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GeolocationQuery>({ ...options, query: Operations.GeolocationDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGeolocation = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GeolocationQuery, Types.GeolocationQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.GeolocationQuery, Types.GeolocationQueryVariables>(Operations.GeolocationDocument, options);
};
export type PageGeolocationComp = React.FC<{data?: Types.GeolocationQuery, error?: Apollo.ApolloError}>;
export const ssrGeolocation = {
      getServerPage: getServerPageGeolocation,
      
      usePage: useGeolocation,
    }
export async function getServerPageGetAddresses
    (options: Omit<Apollo.QueryOptions<Types.GetAddressesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GetAddressesQuery>({ ...options, query: Operations.GetAddressesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetAddresses = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetAddressesQuery, Types.GetAddressesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.GetAddressesQuery, Types.GetAddressesQueryVariables>(Operations.GetAddressesDocument, options);
};
export type PageGetAddressesComp = React.FC<{data?: Types.GetAddressesQuery, error?: Apollo.ApolloError}>;
export const ssrGetAddresses = {
      getServerPage: getServerPageGetAddresses,
      
      usePage: useGetAddresses,
    }
export async function getServerPageHasUserBeenMigrated
    (options: Omit<Apollo.QueryOptions<Types.HasUserBeenMigratedQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.HasUserBeenMigratedQuery>({ ...options, query: Operations.HasUserBeenMigratedDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useHasUserBeenMigrated = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.HasUserBeenMigratedQuery, Types.HasUserBeenMigratedQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.HasUserBeenMigratedQuery, Types.HasUserBeenMigratedQueryVariables>(Operations.HasUserBeenMigratedDocument, options);
};
export type PageHasUserBeenMigratedComp = React.FC<{data?: Types.HasUserBeenMigratedQuery, error?: Apollo.ApolloError}>;
export const ssrHasUserBeenMigrated = {
      getServerPage: getServerPageHasUserBeenMigrated,
      
      usePage: useHasUserBeenMigrated,
    }
export async function getServerPageLocationAutocomplete
    (options: Omit<Apollo.QueryOptions<Types.LocationAutocompleteQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.LocationAutocompleteQuery>({ ...options, query: Operations.LocationAutocompleteDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useLocationAutocomplete = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.LocationAutocompleteQuery, Types.LocationAutocompleteQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.LocationAutocompleteQuery, Types.LocationAutocompleteQueryVariables>(Operations.LocationAutocompleteDocument, options);
};
export type PageLocationAutocompleteComp = React.FC<{data?: Types.LocationAutocompleteQuery, error?: Apollo.ApolloError}>;
export const ssrLocationAutocomplete = {
      getServerPage: getServerPageLocationAutocomplete,
      
      usePage: useLocationAutocomplete,
    }
export async function getServerPageLocationByPlaceId
    (options: Omit<Apollo.QueryOptions<Types.LocationByPlaceIdQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.LocationByPlaceIdQuery>({ ...options, query: Operations.LocationByPlaceIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useLocationByPlaceId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.LocationByPlaceIdQuery, Types.LocationByPlaceIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.LocationByPlaceIdQuery, Types.LocationByPlaceIdQueryVariables>(Operations.LocationByPlaceIdDocument, options);
};
export type PageLocationByPlaceIdComp = React.FC<{data?: Types.LocationByPlaceIdQuery, error?: Apollo.ApolloError}>;
export const ssrLocationByPlaceId = {
      getServerPage: getServerPageLocationByPlaceId,
      
      usePage: useLocationByPlaceId,
    }
export async function getServerPageLocationByUriComponent
    (options: Omit<Apollo.QueryOptions<Types.LocationByUriComponentQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.LocationByUriComponentQuery>({ ...options, query: Operations.LocationByUriComponentDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useLocationByUriComponent = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.LocationByUriComponentQuery, Types.LocationByUriComponentQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.LocationByUriComponentQuery, Types.LocationByUriComponentQueryVariables>(Operations.LocationByUriComponentDocument, options);
};
export type PageLocationByUriComponentComp = React.FC<{data?: Types.LocationByUriComponentQuery, error?: Apollo.ApolloError}>;
export const ssrLocationByUriComponent = {
      getServerPage: getServerPageLocationByUriComponent,
      
      usePage: useLocationByUriComponent,
    }
export async function getServerPageMe
    (options: Omit<Apollo.QueryOptions<Types.MeQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.MeQuery>({ ...options, query: Operations.MeDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useMe = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.MeQuery, Types.MeQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.MeQuery, Types.MeQueryVariables>(Operations.MeDocument, options);
};
export type PageMeComp = React.FC<{data?: Types.MeQuery, error?: Apollo.ApolloError}>;
export const ssrMe = {
      getServerPage: getServerPageMe,
      
      usePage: useMe,
    }
export async function getServerPageCheckVatId
    (options: Omit<Apollo.QueryOptions<Types.CheckVatIdQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CheckVatIdQuery>({ ...options, query: Operations.CheckVatIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useCheckVatId = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.CheckVatIdQuery, Types.CheckVatIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.CheckVatIdQuery, Types.CheckVatIdQueryVariables>(Operations.CheckVatIdDocument, options);
};
export type PageCheckVatIdComp = React.FC<{data?: Types.CheckVatIdQuery, error?: Apollo.ApolloError}>;
export const ssrCheckVatId = {
      getServerPage: getServerPageCheckVatId,
      
      usePage: useCheckVatId,
    }
export async function getServerPageGetorganizationMembers
    (options: Omit<Apollo.QueryOptions<Types.GetorganizationMembersQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.GetorganizationMembersQuery>({ ...options, query: Operations.GetorganizationMembersDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetorganizationMembers = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.GetorganizationMembersQuery, Types.GetorganizationMembersQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.GetorganizationMembersQuery, Types.GetorganizationMembersQueryVariables>(Operations.GetorganizationMembersDocument, options);
};
export type PageGetorganizationMembersComp = React.FC<{data?: Types.GetorganizationMembersQuery, error?: Apollo.ApolloError}>;
export const ssrGetorganizationMembers = {
      getServerPage: getServerPageGetorganizationMembers,
      
      usePage: useGetorganizationMembers,
    }
export async function getServerPageBooking
    (options: Omit<Apollo.QueryOptions<Types.BookingPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BookingPageQuery>({ ...options, query: Operations.BookingPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBooking = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BookingPageQuery, Types.BookingPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.BookingPageQuery, Types.BookingPageQueryVariables>(Operations.BookingPageDocument, options);
};
export type PageBookingComp = React.FC<{data?: Types.BookingPageQuery, error?: Apollo.ApolloError}>;
export const ssrBooking = {
      getServerPage: getServerPageBooking,
      
      usePage: useBooking,
    }
export async function getServerPageBookings
    (options: Omit<Apollo.QueryOptions<Types.BookingsPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.BookingsPageQuery>({ ...options, query: Operations.BookingsPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useBookings = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.BookingsPageQuery, Types.BookingsPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.BookingsPageQuery, Types.BookingsPageQueryVariables>(Operations.BookingsPageDocument, options);
};
export type PageBookingsComp = React.FC<{data?: Types.BookingsPageQuery, error?: Apollo.ApolloError}>;
export const ssrBookings = {
      getServerPage: getServerPageBookings,
      
      usePage: useBookings,
    }
export async function getServerPageConfiguratorLogin
    (options: Omit<Apollo.QueryOptions<Types.ConfiguratorLoginPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ConfiguratorLoginPageQuery>({ ...options, query: Operations.ConfiguratorLoginPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useConfiguratorLogin = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ConfiguratorLoginPageQuery, Types.ConfiguratorLoginPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.ConfiguratorLoginPageQuery, Types.ConfiguratorLoginPageQueryVariables>(Operations.ConfiguratorLoginPageDocument, options);
};
export type PageConfiguratorLoginComp = React.FC<{data?: Types.ConfiguratorLoginPageQuery, error?: Apollo.ApolloError}>;
export const ssrConfiguratorLogin = {
      getServerPage: getServerPageConfiguratorLogin,
      
      usePage: useConfiguratorLogin,
    }
export async function getServerPageContent
    (options: Omit<Apollo.QueryOptions<Types.ContentPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ContentPageQuery>({ ...options, query: Operations.ContentPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useContent = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ContentPageQuery, Types.ContentPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.ContentPageQuery, Types.ContentPageQueryVariables>(Operations.ContentPageDocument, options);
};
export type PageContentComp = React.FC<{data?: Types.ContentPageQuery, error?: Apollo.ApolloError}>;
export const ssrContent = {
      getServerPage: getServerPageContent,
      
      usePage: useContent,
    }
export async function getServerPageSitemapProductTypes
    (options: Omit<Apollo.QueryOptions<Types.SitemapProductTypesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.SitemapProductTypesQuery>({ ...options, query: Operations.SitemapProductTypesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useSitemapProductTypes = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SitemapProductTypesQuery, Types.SitemapProductTypesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.SitemapProductTypesQuery, Types.SitemapProductTypesQueryVariables>(Operations.SitemapProductTypesDocument, options);
};
export type PageSitemapProductTypesComp = React.FC<{data?: Types.SitemapProductTypesQuery, error?: Apollo.ApolloError}>;
export const ssrSitemapProductTypes = {
      getServerPage: getServerPageSitemapProductTypes,
      
      usePage: useSitemapProductTypes,
    }
export async function getServerPageSearchData
    (options: Omit<Apollo.QueryOptions<Types.SearchPageDataQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.SearchPageDataQuery>({ ...options, query: Operations.SearchPageDataDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useSearchData = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.SearchPageDataQuery, Types.SearchPageDataQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.SearchPageDataQuery, Types.SearchPageDataQueryVariables>(Operations.SearchPageDataDocument, options);
};
export type PageSearchDataComp = React.FC<{data?: Types.SearchPageDataQuery, error?: Apollo.ApolloError}>;
export const ssrSearchData = {
      getServerPage: getServerPageSearchData,
      
      usePage: useSearchData,
    }
export async function getServerPageStaticSearch
    (options: Omit<Apollo.QueryOptions<Types.StaticSearchPageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.StaticSearchPageQuery>({ ...options, query: Operations.StaticSearchPageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useStaticSearch = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.StaticSearchPageQuery, Types.StaticSearchPageQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.StaticSearchPageQuery, Types.StaticSearchPageQueryVariables>(Operations.StaticSearchPageDocument, options);
};
export type PageStaticSearchComp = React.FC<{data?: Types.StaticSearchPageQuery, error?: Apollo.ApolloError}>;
export const ssrStaticSearch = {
      getServerPage: getServerPageStaticSearch,
      
      usePage: useStaticSearch,
    }
export async function getServerPagePlace
    (options: Omit<Apollo.QueryOptions<Types.PlaceQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.PlaceQuery>({ ...options, query: Operations.PlaceDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const usePlace = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.PlaceQuery, Types.PlaceQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.PlaceQuery, Types.PlaceQueryVariables>(Operations.PlaceDocument, options);
};
export type PagePlaceComp = React.FC<{data?: Types.PlaceQuery, error?: Apollo.ApolloError}>;
export const ssrPlace = {
      getServerPage: getServerPagePlace,
      
      usePage: usePlace,
    }
export async function getServerPagePlaces
    (options: Omit<Apollo.QueryOptions<Types.PlacesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.PlacesQuery>({ ...options, query: Operations.PlacesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const usePlaces = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.PlacesQuery, Types.PlacesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.PlacesQuery, Types.PlacesQueryVariables>(Operations.PlacesDocument, options);
};
export type PagePlacesComp = React.FC<{data?: Types.PlacesQuery, error?: Apollo.ApolloError}>;
export const ssrPlaces = {
      getServerPage: getServerPagePlaces,
      
      usePage: usePlaces,
    }
export async function getServerPageProductBundle
    (options: Omit<Apollo.QueryOptions<Types.ProductBundleQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ProductBundleQuery>({ ...options, query: Operations.ProductBundleDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProductBundle = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProductBundleQuery, Types.ProductBundleQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.ProductBundleQuery, Types.ProductBundleQueryVariables>(Operations.ProductBundleDocument, options);
};
export type PageProductBundleComp = React.FC<{data?: Types.ProductBundleQuery, error?: Apollo.ApolloError}>;
export const ssrProductBundle = {
      getServerPage: getServerPageProductBundle,
      
      usePage: useProductBundle,
    }
export async function getServerPageProductSearchFilter
    (options: Omit<Apollo.QueryOptions<Types.ProductSearchFilterQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ProductSearchFilterQuery>({ ...options, query: Operations.ProductSearchFilterDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProductSearchFilter = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProductSearchFilterQuery, Types.ProductSearchFilterQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.ProductSearchFilterQuery, Types.ProductSearchFilterQueryVariables>(Operations.ProductSearchFilterDocument, options);
};
export type PageProductSearchFilterComp = React.FC<{data?: Types.ProductSearchFilterQuery, error?: Apollo.ApolloError}>;
export const ssrProductSearchFilter = {
      getServerPage: getServerPageProductSearchFilter,
      
      usePage: useProductSearchFilter,
    }
export async function getServerPageProductSearch
    (options: Omit<Apollo.QueryOptions<Types.ProductSearchQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ProductSearchQuery>({ ...options, query: Operations.ProductSearchDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProductSearch = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProductSearchQuery, Types.ProductSearchQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.ProductSearchQuery, Types.ProductSearchQueryVariables>(Operations.ProductSearchDocument, options);
};
export type PageProductSearchComp = React.FC<{data?: Types.ProductSearchQuery, error?: Apollo.ApolloError}>;
export const ssrProductSearch = {
      getServerPage: getServerPageProductSearch,
      
      usePage: useProductSearch,
    }
export async function getServerPageProductTypes
    (options: Omit<Apollo.QueryOptions<Types.ProductTypesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ProductTypesQuery>({ ...options, query: Operations.ProductTypesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProductTypes = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProductTypesQuery, Types.ProductTypesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.ProductTypesQuery, Types.ProductTypesQueryVariables>(Operations.ProductTypesDocument, options);
};
export type PageProductTypesComp = React.FC<{data?: Types.ProductTypesQuery, error?: Apollo.ApolloError}>;
export const ssrProductTypes = {
      getServerPage: getServerPageProductTypes,
      
      usePage: useProductTypes,
    }
export async function getServerPageProjects
    (options: Omit<Apollo.QueryOptions<Types.ProjectsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ProjectsQuery>({ ...options, query: Operations.ProjectsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useProjects = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ProjectsQuery, Types.ProjectsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.ProjectsQuery, Types.ProjectsQueryVariables>(Operations.ProjectsDocument, options);
};
export type PageProjectsComp = React.FC<{data?: Types.ProjectsQuery, error?: Apollo.ApolloError}>;
export const ssrProjects = {
      getServerPage: getServerPageProjects,
      
      usePage: useProjects,
    }
export async function getServerPagePropertyDescriptors
    (options: Omit<Apollo.QueryOptions<Types.PropertyDescriptorsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.PropertyDescriptorsQuery>({ ...options, query: Operations.PropertyDescriptorsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const usePropertyDescriptors = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.PropertyDescriptorsQuery, Types.PropertyDescriptorsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.PropertyDescriptorsQuery, Types.PropertyDescriptorsQueryVariables>(Operations.PropertyDescriptorsDocument, options);
};
export type PagePropertyDescriptorsComp = React.FC<{data?: Types.PropertyDescriptorsQuery, error?: Apollo.ApolloError}>;
export const ssrPropertyDescriptors = {
      getServerPage: getServerPagePropertyDescriptors,
      
      usePage: usePropertyDescriptors,
    }
export async function getServerPageRelatedProducts
    (options: Omit<Apollo.QueryOptions<Types.RelatedProductsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.RelatedProductsQuery>({ ...options, query: Operations.RelatedProductsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useRelatedProducts = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.RelatedProductsQuery, Types.RelatedProductsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.RelatedProductsQuery, Types.RelatedProductsQueryVariables>(Operations.RelatedProductsDocument, options);
};
export type PageRelatedProductsComp = React.FC<{data?: Types.RelatedProductsQuery, error?: Apollo.ApolloError}>;
export const ssrRelatedProducts = {
      getServerPage: getServerPageRelatedProducts,
      
      usePage: useRelatedProducts,
    }
export async function getServerPageRentalStationById
    (options: Omit<Apollo.QueryOptions<Types.RentalStationByIdQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.RentalStationByIdQuery>({ ...options, query: Operations.RentalStationByIdDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useRentalStationById = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.RentalStationByIdQuery, Types.RentalStationByIdQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.RentalStationByIdQuery, Types.RentalStationByIdQueryVariables>(Operations.RentalStationByIdDocument, options);
};
export type PageRentalStationByIdComp = React.FC<{data?: Types.RentalStationByIdQuery, error?: Apollo.ApolloError}>;
export const ssrRentalStationById = {
      getServerPage: getServerPageRentalStationById,
      
      usePage: useRentalStationById,
    }
export async function getServerPageRentalStations
    (options: Omit<Apollo.QueryOptions<Types.RentalStationsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.RentalStationsQuery>({ ...options, query: Operations.RentalStationsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useRentalStations = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.RentalStationsQuery, Types.RentalStationsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.RentalStationsQuery, Types.RentalStationsQueryVariables>(Operations.RentalStationsDocument, options);
};
export type PageRentalStationsComp = React.FC<{data?: Types.RentalStationsQuery, error?: Apollo.ApolloError}>;
export const ssrRentalStations = {
      getServerPage: getServerPageRentalStations,
      
      usePage: useRentalStations,
    }
export async function getServerPageServiceInfo
    (options: Omit<Apollo.QueryOptions<Types.ServiceInfoQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ServiceInfoQuery>({ ...options, query: Operations.ServiceInfoDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useServiceInfo = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ServiceInfoQuery, Types.ServiceInfoQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.ServiceInfoQuery, Types.ServiceInfoQueryVariables>(Operations.ServiceInfoDocument, options);
};
export type PageServiceInfoComp = React.FC<{data?: Types.ServiceInfoQuery, error?: Apollo.ApolloError}>;
export const ssrServiceInfo = {
      getServerPage: getServerPageServiceInfo,
      
      usePage: useServiceInfo,
    }
export async function getServerPageRecommendedProductsSlice
    (options: Omit<Apollo.QueryOptions<Types.RecommendedProductsSliceQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.RecommendedProductsSliceQuery>({ ...options, query: Operations.RecommendedProductsSliceDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useRecommendedProductsSlice = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.RecommendedProductsSliceQuery, Types.RecommendedProductsSliceQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.RecommendedProductsSliceQuery, Types.RecommendedProductsSliceQueryVariables>(Operations.RecommendedProductsSliceDocument, options);
};
export type PageRecommendedProductsSliceComp = React.FC<{data?: Types.RecommendedProductsSliceQuery, error?: Apollo.ApolloError}>;
export const ssrRecommendedProductsSlice = {
      getServerPage: getServerPageRecommendedProductsSlice,
      
      usePage: useRecommendedProductsSlice,
    }
export async function getServerPageRentalStationsSlice
    (options: Omit<Apollo.QueryOptions<Types.RentalStationsSliceQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.RentalStationsSliceQuery>({ ...options, query: Operations.RentalStationsSliceDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useRentalStationsSlice = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.RentalStationsSliceQuery, Types.RentalStationsSliceQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.RentalStationsSliceQuery, Types.RentalStationsSliceQueryVariables>(Operations.RentalStationsSliceDocument, options);
};
export type PageRentalStationsSliceComp = React.FC<{data?: Types.RentalStationsSliceQuery, error?: Apollo.ApolloError}>;
export const ssrRentalStationsSlice = {
      getServerPage: getServerPageRentalStationsSlice,
      
      usePage: useRentalStationsSlice,
    }
export async function getServerPageTenantByKey
    (options: Omit<Apollo.QueryOptions<Types.TenantByKeyQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.TenantByKeyQuery>({ ...options, query: Operations.TenantByKeyDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useTenantByKey = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.TenantByKeyQuery, Types.TenantByKeyQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.TenantByKeyQuery, Types.TenantByKeyQueryVariables>(Operations.TenantByKeyDocument, options);
};
export type PageTenantByKeyComp = React.FC<{data?: Types.TenantByKeyQuery, error?: Apollo.ApolloError}>;
export const ssrTenantByKey = {
      getServerPage: getServerPageTenantByKey,
      
      usePage: useTenantByKey,
    }
export async function getServerPageTenants
    (options: Omit<Apollo.QueryOptions<Types.TenantsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.TenantsQuery>({ ...options, query: Operations.TenantsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useTenants = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.TenantsQuery, Types.TenantsQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.TenantsQuery, Types.TenantsQueryVariables>(Operations.TenantsDocument, options);
};
export type PageTenantsComp = React.FC<{data?: Types.TenantsQuery, error?: Apollo.ApolloError}>;
export const ssrTenants = {
      getServerPage: getServerPageTenants,
      
      usePage: useTenants,
    }
export async function getServerPageValidateAddresses
    (options: Omit<Apollo.QueryOptions<Types.ValidateAddressesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ValidateAddressesQuery>({ ...options, query: Operations.ValidateAddressesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useValidateAddresses = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<Types.ValidateAddressesQuery, Types.ValidateAddressesQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery<Types.ValidateAddressesQuery, Types.ValidateAddressesQueryVariables>(Operations.ValidateAddressesDocument, options);
};
export type PageValidateAddressesComp = React.FC<{data?: Types.ValidateAddressesQuery, error?: Apollo.ApolloError}>;
export const ssrValidateAddresses = {
      getServerPage: getServerPageValidateAddresses,
      
      usePage: useValidateAddresses,
    }