import * as React from 'react';
function SvgLogout(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M19.981 4.403a12.506 12.506 0 00-14.177 6.209.834.834 0 001.471.785 10.836 10.836 0 11-.185 9.847.834.834 0 00-1.499.729 12.503 12.503 0 0020.913 2.45 12.497 12.497 0 00.29-15.474 12.507 12.507 0 00-6.813-4.546zm-3.735 7.342a.835.835 0 011.179 0l4.148 4.148a.832.832 0 01.02 1.198l-4.167 4.167a.835.835 0 01-1.179-1.179l2.745-2.745H3.501a.833.833 0 010-1.666H18.99l-2.744-2.744a.835.835 0 010-1.179z" />
    </svg>
  );
}
export default SvgLogout;
