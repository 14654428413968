import * as React from 'react';
function SvgZoom(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M5.667 4.833a.833.833 0 00-.833.833v1.667a.833.833 0 01-1.666 0V5.666a2.5 2.5 0 012.5-2.5h1.667a.833.833 0 010 1.666H5.668zm5-.833c0-.46.373-.833.833-.833h3.333a.833.833 0 010 1.666H11.5A.833.833 0 0110.667 4zm6.666 0c0-.46.373-.833.833-.833h3.333a.833.833 0 010 1.666h-3.333A.833.833 0 0117.333 4zm7.5 0c0-.46.373-.833.833-.833h1.667a2.5 2.5 0 012.5 2.5v1.667a.833.833 0 01-1.666 0V5.667a.833.833 0 00-.833-.833h-1.667a.833.833 0 01-.833-.833zM4 10.667c.46 0 .833.373.833.833v3.333a.833.833 0 01-1.666 0V11.5c0-.46.373-.833.833-.833zm25 0c.46 0 .833.373.833.833v3.333a.833.833 0 01-1.666 0V11.5c0-.46.373-.833.833-.833zm-12.87 1.404A7.498 7.498 0 0126.5 19a7.498 7.498 0 01-1.64 4.681l4.729 4.73a.834.834 0 01-1.178 1.178l-4.73-4.729A7.486 7.486 0 0119 26.5a7.498 7.498 0 01-7.5-7.5 7.498 7.498 0 014.63-6.929zM19 13.167a5.832 5.832 0 00-5.834 5.834A5.832 5.832 0 0019 24.835a5.832 5.832 0 005.834-5.834A5.832 5.832 0 0019 13.167zm0 2.5c.46 0 .833.373.833.833v1.667H21.5a.833.833 0 010 1.666h-1.667V21.5a.833.833 0 01-1.666 0v-1.667H16.5a.833.833 0 010-1.666h1.667V16.5c0-.46.373-.833.833-.833zM4 17.333c.46 0 .833.373.833.833v3.333a.833.833 0 01-1.666 0v-3.333c0-.46.373-.833.833-.833zm0 7.5c.46 0 .833.373.833.833v1.667a.833.833 0 00.833.833h1.667a.833.833 0 010 1.666H5.666a2.5 2.5 0 01-2.5-2.5v-1.667c0-.46.373-.833.833-.833zM10.667 29c0-.46.373-.833.833-.833h3.333a.833.833 0 010 1.666H11.5a.833.833 0 01-.833-.833z" />
    </svg>
  );
}
export default SvgZoom;
