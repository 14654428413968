import * as React from 'react';
function SvgViewOn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M15.824 24.332c-4.814 0-9.596-3.378-12.466-6.538a2.677 2.677 0 01-.002-3.591c2.871-3.161 7.658-6.537 12.482-6.537l.168.001.17-.001c4.809 0 9.593 3.376 12.466 6.534a2.677 2.677 0 01.002 3.592c-2.873 3.162-7.663 6.54-12.495 6.54l-.165-.001h-.16zm.013-15c-4.279 0-8.614 3.093-11.244 5.989-.347.385-.347.968.002 1.356 2.629 2.894 6.963 5.989 11.244 5.989l.146-.001.151.001c4.297 0 8.64-3.095 11.272-5.991a1.01 1.01 0 00-.002-1.355c-2.631-2.893-6.969-5.986-11.248-5.986l-.173.001-.148-.002z" />
      <path d="M15.998 20.998c-1.335 0-2.591-.521-3.535-1.465s-1.464-2.2-1.463-3.536a5.005 5.005 0 015-4.999 4.97 4.97 0 013.535 1.463A4.962 4.962 0 0121 15.995c0 1.34-.521 2.594-1.466 3.539A4.968 4.968 0 0116 20.997h-.001zM16 12.665a3.336 3.336 0 00-3.333 3.332c0 .89.347 1.728.976 2.357.63.63 1.467.977 2.357.977.891 0 1.728-.347 2.357-.976s.977-1.467.977-2.357a3.31 3.31 0 00-.977-2.359 3.313 3.313 0 00-2.356-.974z" />
    </svg>
  );
}
export default SvgViewOn;
