import * as React from 'react';
function SvgClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M11.068 9.702l4.933 4.933 4.932-4.932 1.366 1.365-4.933 4.933 4.932 4.933-1.365 1.365L16 17.366l-4.933 4.933-1.365-1.365 4.933-4.933-4.933-4.933 1.366-1.365zm14.36-3.13c5.207 5.207 5.207 13.649 0 18.856s-13.649 5.207-18.856 0c-5.207-5.207-5.207-13.649 0-18.856s13.649-5.207 18.856 0zM7.938 24.063c4.446 4.446 11.679 4.446 16.126 0s4.446-11.679 0-16.126-11.68-4.446-16.126 0c-4.446 4.446-4.446 11.679 0 16.125z" />
    </svg>
  );
}
export default SvgClose;
