import { FC, useEffect, useState } from 'react';
import { Icon } from '../../atoms';
import styles from './pagination.module.scss';
import classNames from 'classnames';
import { PageAllButtons } from './page-all-buttons';
import { PageSeperateButtons } from './page-seperate-buttons';

export type DigandoPaginationProps = {
  totalPages: number;
  initialCurrentPage: number;
  onPageChange: (page: number) => void;
}

export const Pagination: FC<DigandoPaginationProps> = ((props) => {
  const { totalPages, initialCurrentPage, onPageChange } = props;
  const [currentPage, setCurrentPage] = useState<number>(initialCurrentPage);

  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage, onPageChange]);

  const changePage = (pageChange: number) => () => {
    const newPage = currentPage + pageChange;
    if (0 < newPage && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className={styles.pagination}>
      <div
        className={classNames({
          [styles.arrow]: true,
          [styles.disabled]: currentPage === 1,
        })}
        onClick={changePage(-1)}
      >
        <Icon icon={'left-slide'} size={'20px'} />
      </div>
      {10 >= totalPages ? (
        <PageAllButtons currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
      ) : (
        <PageSeperateButtons currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
      )}
      <div
        className={classNames({
          [styles.arrow]: true,
          [styles.disabled]: currentPage === totalPages,
        })}
        onClick={changePage(1)}
      >
        <Icon icon={'right-slide'} size={'20px'} />
      </div>
    </div>
  );
});
