import { Error } from './errorInterface';
import { useEffect, useState } from 'react';

export enum ValidationTypes {
  REQUIRED = 'required',
  MIN_LENGTH = 'min-length',
  MAX_LENGTH = 'max-length',
  CHECKBOX_ACTIVE = 'checkbox-active',
  EMAIL = 'email',
  COMPARE = 'compare',
}

export type InputValidationRule = {
  type: ValidationTypes;
  value?: number | string;
  message: string;
}

export type InputErrorType = {
  isValid: boolean;
  errorCode: string;
  message: string
};

export type UseFormReturn = {
  validate: (name: string, rules: InputValidationRule[], value: string | boolean) => InputErrorType | null;
  isFormValid: boolean;
  hasFormSubmitted: boolean;
  setHasFormSubmitted: (value: boolean) => void;
  registerElement: (element: HTMLInputElement, rules: InputValidationRule[], name: string) => void;
  unregisterElement: (elementId: string) => void;
  validateForm: () => boolean;
};

export const useForm = (): UseFormReturn => {
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [hasFormSubmitted, setHasFormSubmitted] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<Error[]>([]);
  const [formElements, setFormElements] = useState<{element: HTMLInputElement; rules: InputValidationRule[], name: string; }[]>([]);

  const checkIfFormIsValid = () => {
    return Object.values(formErrors).every(value => !value);
  }

  useEffect(() => {
    setIsFormValid(checkIfFormIsValid());
  }, [formErrors]);

  /**
   * Register a Input Element to the form.
   * This will be done automatically when the Input Component is mounted.
   */
  const registerElement = (element: HTMLInputElement, rules: InputValidationRule[], name: string) => {
    setFormElements((prev) => {
      return [...prev, { element, rules, name }];
    });
  }

  /**
   * Unregister a Input Element from the form.
   * This will be done automatically when the Input Component is unmounted.
   */
  const unregisterElement = (name: string) => {
    setFormElements((prev) => {
      return prev.filter(item => item.name !== name);
    });

    // remove error from formErrors
    setFormErrors((prev) => {
      const newFormErrors = { [name]: false };
      return { ...prev, ...newFormErrors };
    });
  }

  /**
   * Validates all Input Elements in the form that has been registered.
   */
  const validateForm = () => {
    setFormErrors(() => {
      return [];
    });

    formElements.forEach(element => {
      validate(element.element.name, element.rules, element.element.value);
    });

    return checkIfFormIsValid();
  }

  const validate = (name: string, rules: InputValidationRule[], value: string | boolean): InputErrorType | null => {
    const errors: InputErrorType[] = [];

    rules.forEach(rule => {
      switch (rule.type) {
        case ValidationTypes.REQUIRED:
          if (!value) {
            errors.push({
              isValid: false,
              errorCode: 'required',
              message: rule.message,
            });
          }
          break;
        case ValidationTypes.MIN_LENGTH:
          if ('string' === typeof value && value.length < (rule?.value as number ?? 0 )) {
            errors.push({
              isValid: false,
              errorCode: 'min-length',
              message: rule.message,
            });
          }
          break;
        case ValidationTypes.MAX_LENGTH:
          if ('string' === typeof value && (rule?.value as number ?? 100) < value.length) {
            errors.push({
              isValid: false,
              errorCode: 'max-length',
              message: rule.message,
            });
          }
          break;
        case ValidationTypes.CHECKBOX_ACTIVE:
          if (!value) {
            errors.push({
              isValid: false,
              errorCode: 'checkbox-active',
              message: rule.message,
            });
          }
          break;
        case ValidationTypes.EMAIL:
          if ('string' === typeof value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            errors.push({
              isValid: false,
              errorCode: 'email',
              message: rule.message,
            });
          }
          break;
        case ValidationTypes.COMPARE:
          if ('string' === typeof value && value !== rule.value) {
            errors.push({
              isValid: false,
              errorCode: 'compare',
              message: rule.message,
            });
          }
          break;
        default:
          break;
      }
    });

    const newFormErrors = { [name]: 0 < errors.length };
    setFormErrors((prev) => {
      return { ...prev, ...newFormErrors };
    });

    return errors?.[0] ?? null;
  };

  return {
    validate,
    isFormValid,
    hasFormSubmitted,
    setHasFormSubmitted,
    registerElement,
    unregisterElement,
    validateForm,
  };
};
