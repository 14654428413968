import * as React from 'react';
function SvgBurger(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M28.381 24.485H3.619c-.253 0-.495.096-.673.266s-.279.402-.279.643v1.818c0 .241.1.472.279.643s.421.266.673.266h24.762c.253 0 .495-.096.673-.266s.279-.402.279-.643v-1.818c0-.241-.1-.472-.279-.643s-.421-.266-.673-.266zm0-7.273H3.619c-.253 0-.495.096-.673.266s-.279.402-.279.643v1.818c0 .241.1.472.279.643s.421.266.673.266h24.762c.253 0 .495-.096.673-.266s.279-.402.279-.643v-1.818c0-.241-.1-.472-.279-.643s-.421-.266-.673-.266zm0-7.273H3.619c-.253 0-.495.096-.673.266s-.279.402-.279.643v1.818c0 .241.1.472.279.643s.421.266.673.266h24.762c.253 0 .495-.096.673-.266s.279-.402.279-.643v-1.818c0-.241-.1-.472-.279-.643s-.421-.266-.673-.266zm0-7.272H3.619c-.253 0-.495.096-.673.266s-.279.402-.279.643v1.818c0 .241.1.472.279.643s.421.266.673.266h24.762c.253 0 .495-.096.673-.266s.279-.402.279-.643V3.576c0-.241-.1-.472-.279-.643s-.421-.266-.673-.266z" />
    </svg>
  );
}
export default SvgBurger;
