import { FC } from 'react';
import styles from './loader.module.scss';
import classNames from 'classnames';

type LoaderProps = {
  invert?: boolean;
  size?: number;
};

export const Loader: FC<LoaderProps> = ({ invert = false, size = 26 }) => {
  return (
    <div
      className={classNames({
        [styles.digandoLoaderBox]: true,
      })}
      style={{
        width: size,
        height: size,
      }}
    >
      <span className={classNames({
        [styles.digandoLoader]: true,
        [styles.digandoLoaderInvert]: invert,
      })}>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </span>
    </div>
  );
};
