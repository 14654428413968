import * as React from 'react';
function SvgZustellung(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M22.667 27.834a2.508 2.508 0 01-2.357-1.667h-9.454c-.349.983-1.291 1.667-2.357 1.667s-2.009-.683-2.357-1.667h-.977a2.502 2.502 0 01-2.5-2.5V21.42c0-1.059.861-1.92 1.92-1.92h13.081V9.617a2.5 2.5 0 00-1.113-2.085L15.54 6.86c-.186-.122-.312-.311-.357-.529s-.001-.44.122-.626a.828.828 0 011.154-.234l1.016.673a4.16 4.16 0 011.856 3.355h4.168a5.84 5.84 0 015.834 5.834v8.334c0 1.379-1.121 2.5-2.5 2.5h-1.81a2.507 2.507 0 01-2.356 1.667zm0-3.333a.833.833 0 100 1.666.833.833 0 000-1.666zm-14.167 0a.833.833 0 100 1.666.833.833 0 000-1.666zm18.334 0c.46 0 .833-.373.833-.833v-.833h-1.341a.833.833 0 010-1.666h1.341v-3.333h-4.762a1.922 1.922 0 01-1.903-1.861v-4.806h-1.668v13.334h.977c.349-.983 1.291-1.667 2.357-1.667s2.009.683 2.357 1.667h1.81zm-9.167 0v-3.333H4.586a.253.253 0 00-.253.253v2.247c0 .46.373.833.833.833h.977c.349-.983 1.291-1.667 2.357-1.667s2.009.683 2.357 1.667h6.81zm5-8.552c.003.112.118.218.249.218h4.751v-.833a4.171 4.171 0 00-4.167-4.167h-.833v4.782z" />
    </svg>
  );
}
export default SvgZustellung;
