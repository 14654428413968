import { FC, ReactElement } from 'react';
import styles from './popconfirm.module.scss';
import classNames from 'classnames';
import { IconTypes, Icon, HintProps } from '../../atoms';
import { Button } from '../../buttons';

/**
 * @example
 *
 * <Popconfirm>
 *   <PopconfirmHeader title={"Das ist ein test"} icon={'close'} />
 *
 *   <PopconfirmContent>
 *     <Hint warn={true}>Das ist ein Text mit einer Warnung für den Kunden</Hint>
 *   </PopconfirmContent>
 *
 *   <PopconfirmFooter
 *     onSubmit={() => { console.log('submit')}}
 *     onCancel={() => { console.log('cancel')}}
 *     submitButtonText={'Submit'}
 *     cancelButtonText={'cancel'}
 *   />
 * </Popconfirm>
 */

export type PopconfirmHeaderProps = {
  title: string;
  icon: IconTypes;
}

export const PopconfirmHeader: FC<PopconfirmHeaderProps> = ({ title, icon }) => {
  return (
    <div className={styles.header}>
      <Icon icon={icon} size={'20px'} color={'var(--primary-color)'} />
      <h4 className={styles.title}>{title}</h4>
    </div>
  );
};

export type PopconfirmContentProps = {
  children: ReactElement<HintProps> | string;
}

export const PopconfirmContent: FC<PopconfirmContentProps> = ({ children }) => {
  const isTextOnly = 'string' === typeof children;

  return (
    <div className={classNames({
      [styles.content]: true,
      [styles.isTextOnly]: isTextOnly,
    })}>
      {children}
    </div>
  );
};

export type PopconfirmFooterProps = {
  onSubmit: () => void;
  onCancel: () => void;
  cancelButtonText: string;
  submitButtonText: string;
  loading?: boolean;
}

export const PopconfirmFooter: FC<PopconfirmFooterProps> = ({ onCancel, onSubmit, cancelButtonText, submitButtonText, loading = false }) => {
  return (
    <div className={styles.footer}>
      <Button type={'button'} small={true} bordered={true} onClick={onCancel} disabled={loading}>{cancelButtonText}</Button>
      <Button type={'submit'} small={true} onClick={onSubmit} loading={loading} disabled={loading}>{submitButtonText}</Button>
    </div>
  );
};

type AllowedChildren = ReactElement<PopconfirmHeaderProps> | ReactElement<PopconfirmContentProps> | ReactElement<PopconfirmFooterProps> | false | null;
export type PopconfirmProps = {
  children: AllowedChildren | Array<AllowedChildren>;
  isOpen: boolean;
}

export const Popconfirm: FC<PopconfirmProps> = ({ children, isOpen }) => {
  return (
    <div className={classNames({
      [styles.popconfirm]: true,
      [styles.isOpen]: isOpen,
    })}>
      <div className={styles.inner}>
        {children}
      </div>

      <span className={styles.arrow}></span>
    </div>
  );
};

