import * as React from 'react';
function SvgYoutube(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M27.944 15.445c.46 0 .833.373.833.833V28.5c0 .46-.373.833-.833.833H3.5a.833.833 0 01-.833-.833V16.278c0-.46.373-.833.833-.833h24.444zm-.833 1.666H4.334v10.556h22.777V17.111zm-12.5 3.611c.418 0 .765.308.824.71l.009.123v3.611a.833.833 0 01-1.268.711 1.947 1.947 0 01-2.611-1.63l-.01-.192v-2.5a.833.833 0 011.657-.123l.009.123v2.5a.276.276 0 00.278.277.276.276 0 00.268-.205l.009-.072v-2.5a.833.833 0 01.833-.833zm2.778-3.055c.418 0 .765.308.824.71l.009.123-.001 2.242a1.942 1.942 0 012.223 1.925v1.389a1.944 1.944 0 01-2.646 1.813.833.833 0 01-1.235-.601l-.009-.123v-6.644c0-.46.373-.833.833-.833zm5.922 2.778a1.944 1.944 0 011.945 1.945.833.833 0 01-.71.824l-.123.009-1.39-.001.001.684a.434.434 0 00.697.34l.064-.06a.834.834 0 011.257 1.095 2.098 2.098 0 01-3.672-1.154l-.012-.215V22.39a1.944 1.944 0 011.945-1.945zM11.3 18.778a.833.833 0 01.123 1.657l-.123.009-1.412-.001.001 4.701a.833.833 0 01-1.657.123l-.009-.123-.001-4.701-1.41.001a.833.833 0 01-.123-1.657l.123-.009h4.489zm7.2 3.611c-.074 0-.144.029-.197.081s-.081.123-.081.196v1.389a.276.276 0 00.278.277.276.276 0 00.277-.278v-1.389a.277.277 0 00-.278-.278zM14 2.833a.834.834 0 01.239 1.052L14.167 4 11 8.219v4.726a.847.847 0 01-.15.476.818.818 0 01-.4.308l-.037.013a.833.833 0 01-1.071-.673l-.009-.123-.001-4.724-3.165-4.22a.833.833 0 01.065-1.077l.102-.09a.833.833 0 011.077.065l.09.102 2.667 3.556 2.667-3.556a.834.834 0 011.167-.167zm1.167 2.612a2.5 2.5 0 012.5 2.5v3.333a2.5 2.5 0 11-5 0V7.945a2.5 2.5 0 012.5-2.5zm8.333 0c.418 0 .765.308.824.71l.009.123v6.667a.833.833 0 01-1.545.434 3.053 3.053 0 01-3.671-.496 3.057 3.057 0 01-.883-1.892l-.012-.269V6.278a.833.833 0 011.657-.123l.009.123v4.444a1.386 1.386 0 001.389 1.389 1.386 1.386 0 001.377-1.206l.012-.183V6.278a.831.831 0 01.833-.833zm-8.333 1.666a.833.833 0 00-.833.833v3.333a.833.833 0 001.666 0V7.944a.833.833 0 00-.833-.833z" />
    </svg>
  );
}
export default SvgYoutube;
