import * as React from 'react';
function SvgPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M25.143 13.714h-6.857V6.857c0-.841-.682-1.524-1.524-1.524h-1.524c-.841 0-1.524.682-1.524 1.524v6.857H6.857c-.841 0-1.524.682-1.524 1.524v1.524c0 .841.682 1.524 1.524 1.524h6.857v6.857c0 .841.682 1.524 1.524 1.524h1.524c.841 0 1.524-.682 1.524-1.524v-6.857h6.857c.841 0 1.524-.682 1.524-1.524v-1.524c0-.841-.682-1.524-1.524-1.524z" />
    </svg>
  );
}
export default SvgPlus;
