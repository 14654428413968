import * as React from 'react';
function SvgCartEmpty(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={59}
      height={26}
      viewBox="0 0 59 32"
      {...props}
    >
      <path d="M14.052 18.667c2.979 0 5.393 2.388 5.393 5.333s-2.415 5.333-5.393 5.333c-2.979 0-5.393-2.388-5.393-5.333s2.415-5.333 5.393-5.333zm41.349-16c.331 0 .599.265.599.593a.594.594 0 01-.492.583l-.108.01h-7.748a.601.601 0 00-.423.173l-.075.091L31.087 27.95c-.3.445-.785.73-1.317.783l-.179.009h-.557a1.791 1.791 0 01-1.79-1.607l-.008-.171v-7.111a.594.594 0 00-.492-.583l-.108-.01h-8.389a.596.596 0 01-.599-.593c0-.291.212-.533.491-.583l.108-.009h8.389c.935 0 1.702.705 1.79 1.607l.008.171v7.111c0 .291.212.533.492.583l.108.01h.557c.16 0 .312-.063.423-.173l.075-.091L46.156 3.46c.3-.445.785-.73 1.317-.783l.179-.009H55.4zM43.416 5.63l-8.389 11.852H14.652L2.667 5.63h40.749z" />
    </svg>
  );
}
export default SvgCartEmpty;
