import { FC } from 'react';
import { PageButton } from './page-button';
import styles from './pagination.module.scss';

export type DigandoPageSeparateProps = {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
}

type VisibleButton = {
  page: number;
  isSkip: boolean;
}

export const PageSeperateButtons: FC<DigandoPageSeparateProps> = ((props) => {
  const { currentPage, totalPages, setCurrentPage } = props;

  // Prevent skipping only 1 button
  const checkSkippedButtons = (skipButtons: number[]): VisibleButton => {
    if (1 === skipButtons.length) {
      return { page: skipButtons[0], isSkip: false };
    }
    return { page: skipButtons[0], isSkip: true };
  };

  const visibleButtons: VisibleButton[] = [];
  let skipButtons = [];
  for (let i = 1; i <= totalPages; i++) {
    if (i === currentPage) {
      visibleButtons.push({ page: i, isSkip: false });
    } else if (i <= 1 || i >= totalPages) {
      // Always show first and last two pages
      if (0 < skipButtons.length) {
        visibleButtons.push(checkSkippedButtons(skipButtons));
        skipButtons = [];
      }

      visibleButtons.push({ page: i, isSkip: false });
    } else if (currentPage - 2 <= i && i <= currentPage + 2) {
      // Show 2 pages before and after current page
      if (0 < skipButtons.length) {
        visibleButtons.push(checkSkippedButtons(skipButtons));
        skipButtons = [];
      }

      visibleButtons.push({ page: i, isSkip: false });
    } else {
      skipButtons.push(i);
    }
  }

  return (
    <div className={styles.buttonsContainer}>
      {visibleButtons.map((page) => (
        page.isSkip ? (
          <div className={styles.skipButton} key={page.page}>...</div>
        ) : (
          <PageButton key={page.page} page={page.page} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        )
      ))}
    </div>
  )
});
