import * as React from 'react';
function SvgCopy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M8.667 22.824a2.844 2.844 0 002.766 2.898l.011-.001h13.634a2.84 2.84 0 002.755-2.883V9.461a2.918 2.918 0 00-.799-2.021l-3.718-3.912a2.747 2.747 0 00-1.982-.861h-9.912c-1.554.043-2.79 1.339-2.754 2.88v17.278zm1.666-17.297a1.162 1.162 0 011.117-1.193h9.88c.291.002.573.126.775.34l3.718 3.912c.222.237.344.547.343.873v13.405a1.16 1.16 0 01-1.111 1.192H11.467a1.177 1.177 0 01-1.134-1.213V5.526zm-5 21.306c0 1.379 1.121 2.5 2.5 2.5h15a.834.834 0 000-1.666h-15A.833.833 0 017 26.834V8.917a.833.833 0 00-1.666 0v17.917z" />
    </svg>
  );
}
export default SvgCopy;
