import * as React from 'react';
function SvgVerfugbarkeit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M25.167 27a2.502 2.502 0 01-2.5-2.5V7.833c0-1.379 1.121-2.5 2.5-2.5h1.667c1.379 0 2.5 1.121 2.5 2.5V24.5c0 1.379-1.121 2.5-2.5 2.5h-1.667zm0-20a.833.833 0 00-.833.833V24.5c0 .46.373.833.833.833h1.667c.46 0 .833-.373.833-.833V7.833A.833.833 0 0026.834 7h-1.667zm-10 20a2.502 2.502 0 01-2.5-2.5v-10c0-1.379 1.121-2.5 2.5-2.5h1.667c1.379 0 2.5 1.121 2.5 2.5v10c0 1.379-1.121 2.5-2.5 2.5h-1.667zm0-13.333a.833.833 0 00-.833.833v10c0 .46.373.833.833.833h1.667c.46 0 .833-.373.833-.833v-10a.833.833 0 00-.833-.833h-1.667zM2.667 24.5c0 1.379 1.121 2.5 2.5 2.5h1.667c1.379 0 2.5-1.121 2.5-2.5v-3.333c0-1.379-1.121-2.5-2.5-2.5H5.167a2.502 2.502 0 00-2.5 2.5V24.5zm1.666-3.333c0-.46.373-.833.833-.833h1.667c.46 0 .833.373.833.833V24.5c0 .46-.373.833-.833.833H5.166a.833.833 0 01-.833-.833v-3.333z" />
    </svg>
  );
}
export default SvgVerfugbarkeit;
