import * as React from 'react';
function SvgLocation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M16.5 4.333A6.67 6.67 0 009.833 11c0 .725.298 1.777.846 3.054.538 1.252 1.277 2.631 2.072 3.977 1.38 2.338 2.899 4.531 3.749 5.72.85-1.189 2.369-3.382 3.749-5.72.795-1.346 1.534-2.725 2.072-3.977.549-1.277.846-2.329.846-3.054A6.67 6.67 0 0016.5 4.333zm-5.893.774A8.333 8.333 0 0124.834 11c0 1.081-.415 2.394-.981 3.712-.577 1.343-1.355 2.788-2.168 4.166-1.628 2.757-3.436 5.305-4.172 6.319l-.002.003a1.251 1.251 0 01-2.02 0l-.002-.003c-.736-1.014-2.544-3.562-4.172-6.319-.813-1.378-1.591-2.824-2.168-4.166-.566-1.318-.981-2.631-.981-3.712 0-2.21.878-4.33 2.441-5.893zm2.947 2.947a4.167 4.167 0 115.892 5.894 4.167 4.167 0 01-5.892-5.894zM16.5 8.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm6.805 13.109a.833.833 0 10-.323 1.635c1.478.293 2.645.674 3.419 1.087.388.207.639.402.785.566.141.158.148.245.148.269 0 .037-.033.258-.55.607-.488.329-1.248.659-2.262.949-2.018.577-4.854.944-8.021.944s-6.003-.368-8.021-.944c-1.014-.29-1.775-.619-2.262-.949-.517-.349-.55-.57-.55-.607 0-.024.007-.11.147-.268.145-.163.395-.358.78-.565.771-.413 1.932-.793 3.402-1.087a.833.833 0 00-.326-1.634c-1.55.309-2.89.73-3.863 1.252-.487.261-.92.566-1.241.928-.326.367-.566.831-.566 1.374 0 .883.62 1.54 1.283 1.988.693.468 1.641.857 2.738 1.17 2.205.63 5.202 1.008 8.479 1.008s6.274-.378 8.479-1.008c1.097-.313 2.045-.702 2.738-1.17.664-.449 1.283-1.105 1.283-1.988 0-.544-.242-1.008-.568-1.376-.322-.363-.757-.669-1.246-.929-.978-.522-2.323-.943-3.881-1.252z" />
    </svg>
  );
}
export default SvgLocation;
