import {
  CartItemFragment,
  ProductBundleQuery,
  PropertyFilter,
  ShippingOption,
} from './../../@types/codegen/graphql';
import { atom, SetterOrUpdater, useRecoilValue, useSetRecoilState, selector, waitForAll } from "recoil";

export type SelectedAccessoryItem = {
  identifier: string;
  quantity: number;
};

interface IProductConfiguratorValues {
  selectedAccessories: SelectedAccessoryItem[];
  selectedRelatedProducts: string[];
  shippingOption: ShippingOption;
  deliveryTime: string | null;
  properties: PropertyFilter[];
  basketItemNote: string;
  editBasketItem: CartItemFragment | null;
  isEndDayValid: boolean;
  isStartDayValid: boolean;
  selectedProductAmount: number;
  tradeNumber: string | null;
}

interface ISetProductConfigurator {
  setSelectedAccessories: SetterOrUpdater<SelectedAccessoryItem[]>;
  setSelectedRelatedProducts: SetterOrUpdater<string[]>;
  setShippingOption: SetterOrUpdater<ShippingOption>;
  setDeliveryTime: SetterOrUpdater<string>;
  setProperties: SetterOrUpdater<PropertyFilter[]>;
  setBasketItemNote: SetterOrUpdater<string>;
  setEditBasketItem: SetterOrUpdater< CartItemFragment | null>;
  setIsStartDayValid: SetterOrUpdater<boolean>;
  setIsEndDayValid: SetterOrUpdater<boolean>;
  setSelectedProductAmount: SetterOrUpdater<number>;
  setTradeNumber: SetterOrUpdater<string | null>;
}

export const editBasketItemState = atom<CartItemFragment | null>({
  key: 'editBasketItem',
  default: null,
});

export const configuratorDataState = atom<ProductBundleQuery | null>({
  key: 'configuratorData',
  default: null,
});

export const selectedAccessoriesState = atom<SelectedAccessoryItem[]>({
  key: 'selectedAccessories',
  default: [],
});

export const selectedAccessoriesManuallyState = atom<boolean>({
  key: 'selectedAccessoriesManually',
  default: false,
});

export const selectedRelatedProductsState = atom<string[]>({
  key: 'selectedRelatedProducts',
  default: [],
});

export const shippingOptionState = atom<ShippingOption>({
  key: 'shippingOption',
  default: ShippingOption.Pickup
});

export const shippingSelectedDeliveryAddressIdState = atom<string | null>({
  key: 'shippingSelectedDeliveryAddressId',
  default: null
});

export const shippingSelectedRentalStationIdState = atom<string | null>({
  key: 'shippingSelectedRentalStationId',
  default: null
});

export const lastSelectedProjectIdState = atom<string | null>({
  key: 'lastSelectedProjectId',
  default: null
});

export const deliveryTimeState = atom<string>({
  key: 'deliveryTime',
  default: '0700-0900'
});

export const propertiesState = atom<PropertyFilter[]>({
  key: 'properties',
  default: []
});

export const basketItemNoteState = atom<string>({
  key: 'basketItemNote',
  default: ''
});

export const isStartDayValidState = atom<boolean>({
  key: 'isStartDayValid',
  default: true
});

export const isEndDayValidState = atom<boolean>({
  key: 'isEndDayValid',
  default: true
});

export const selectedProductAmountState = atom<number>({
  key: 'selectedProductAmount',
  default: 1
});

export const attachedPdfState = atom<File | string | null>({
  key: 'attachedPdf',
  default: null
});

export const tradeNumberState = atom<string | null>({
  key: 'tradeNumber',
  default: null
});

export const aggregatedConfiguratorDataSelector = selector({
  key: 'aggregatedDataSelector',
  get: ({ get }) => {
    const [
      properties,
      shippingOption,
      selectedAccessories,
      selectedRelatedProducts,
      selectedProductAmount,
      selectedAccessoriesManually,
    ] = get(waitForAll([propertiesState, shippingOptionState, selectedAccessoriesState, selectedRelatedProductsState, selectedProductAmountState, selectedAccessoriesManuallyState]));
    return {
      properties,
      shippingOption,
      selectedAccessories,
      selectedRelatedProducts,
      selectedProductAmount,
      selectedAccessoriesManually
    };
  },
});

export const productConfiguratorAtoms = {
  selectedAccessories: selectedAccessoriesState,
  selectedRelatedProducts: selectedRelatedProductsState,
  shippingOption: shippingOptionState,
  properties: propertiesState,
  configuratorData: configuratorDataState,
  basketItemNote: basketItemNoteState,
  editBasketItem: editBasketItemState,
  selectedProductAmount: selectedProductAmountState,
  tradeNumber: tradeNumberState,
  selectedRentalStationId: shippingSelectedRentalStationIdState,
};

export const useProductConfiguratorValues = (): IProductConfiguratorValues => ({
  selectedAccessories: useRecoilValue(selectedAccessoriesState),
  selectedRelatedProducts: useRecoilValue(selectedRelatedProductsState),
  shippingOption: useRecoilValue(shippingOptionState),
  deliveryTime: useRecoilValue(deliveryTimeState),
  properties: useRecoilValue(propertiesState),
  basketItemNote: useRecoilValue(basketItemNoteState),
  editBasketItem: useRecoilValue(editBasketItemState),
  isStartDayValid: useRecoilValue(isStartDayValidState),
  isEndDayValid: useRecoilValue(isEndDayValidState),
  selectedProductAmount: useRecoilValue(selectedProductAmountState),
  tradeNumber: useRecoilValue(tradeNumberState),
});

export const useSetProductConfigurator = (): ISetProductConfigurator => ({
  setSelectedAccessories: useSetRecoilState(selectedAccessoriesState),
  setSelectedRelatedProducts: useSetRecoilState(selectedRelatedProductsState),
  setShippingOption: useSetRecoilState(shippingOptionState),
  setDeliveryTime: useSetRecoilState(deliveryTimeState),
  setProperties: useSetRecoilState(propertiesState),
  setBasketItemNote: useSetRecoilState(basketItemNoteState),
  setEditBasketItem: useSetRecoilState(editBasketItemState),
  setIsStartDayValid: useSetRecoilState(isStartDayValidState),
  setIsEndDayValid: useSetRecoilState(isEndDayValidState),
  setSelectedProductAmount: useSetRecoilState(selectedProductAmountState),
  setTradeNumber: useSetRecoilState(tradeNumberState),
});
