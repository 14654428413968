import { FC, createRef, forwardRef, useEffect, useState } from 'react';
import styles from './inputs.module.scss';
import { Icon, Loader } from '../atoms';
import { themeVariables } from '../foundation';
import classNames from 'classnames';
import { InputErrorType, InputValidationRule, UseFormReturn } from './form.hook';
import { InputError } from './input-error';

export type IInputProps = {
  id: string;
  name: string;
  label: string;
  error?: InputErrorType | null;
  defaultValue?: string | number;
  isLoading?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  /**
   * The event parameter is the React ChangeEvent and the value is the new value of the input.
   * We keep the event parameter at the first position to be sure a third party dependency can't break by using the onChange callback of the input.
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  onDelete?: () => void;
  value?: string;
  type?: string;
  ghost?: boolean;
  maxLength?: number;
  disabled?: boolean;
  rules?: InputValidationRule[];
  form?: UseFormReturn;
  placeholder?: string;
  hidden?: boolean;
  suffix?: React.ReactNode;
  min?: number;
  max?: number;
  inputMaxWidth?: string;
  /**
   * If true, the input will be rendered as a native date or time picker.
   */
  native?: boolean;
  suffixAction?: 'focus' | 'clear';
  isClearable?: boolean;
  onClear?: () => void;
}

type InputGroupProps = {
  spacing?: 2 | 4 | 6 | 8 | 10 | 14;
  children: React.ReactNode;
  noBottomSpacing?: boolean;
}

export const InputGroup: FC<InputGroupProps> = ({ children, noBottomSpacing, spacing = 2 }) => {
  return <div className={classNames({
    [styles[`inputGroup${spacing}`]]: true,
    [styles.noBottomSpacing]: noBottomSpacing,
  })}>{children}</div>;
};

export const Input: FC<IInputProps> = forwardRef<HTMLInputElement, IInputProps>((props, inputRef) => {
  const {
    name,
    label,
    defaultValue = '',
    error = null,
    isLoading = false,
    autoComplete,
    autoFocus = false,
    id,
    onChange,
    onFocus,
    onBlur,
    onClick,
    onDelete,
    value,
    type = 'text',
    maxLength,
    disabled = false,
    rules = [],
    placeholder,
    hidden = false,
    suffix,
    min,
    max,
    inputMaxWidth,
    native = false,
    suffixAction = 'clear',
    isClearable = false ,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClear = () => {},
  } = props;

  const [internalValue, setInternalValue] = useState<string>(defaultValue.toString());
  const [inputError, setInputError] = useState<InputErrorType | null>(error);
  let ref = createRef<HTMLInputElement>();

  if (typeof inputRef === 'function') {
    inputRef(ref.current);
  } else if (inputRef) {
    ref = inputRef;
  }

  /**
   * Register and Unregister the Input Element to the Form.
   */
  useEffect(() => {
    if (ref.current) {
      props?.form?.registerElement(ref.current, rules, name);
    }

    return () => {
      props?.form?.unregisterElement(name);
    };
  }, [ref.current]);

  useEffect(() => {
    if (value !== undefined && value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  useEffect(() => {
    setInputError(error);
  }, [error]);

  useEffect(() => {
    if (props.form) {
      const validationError = props.form.validate(name, rules, internalValue);

      if (validationError) {
        setInputError(validationError);
      } else if (error) {
        setInputError(error);
      } else {
        setInputError(null);
      }
    }
  }, [internalValue]);

  return (
    <div className={styles.inputWrapper}>
      <div
        className={classNames(styles.inputContainer, {
          [styles.hasError]: error && !error.isValid && true === (props?.form?.hasFormSubmitted ?? true),
          [styles.hasValue]: '' !== (value ?? '') || '' !== internalValue || placeholder,
          [styles.hidden]: hidden,
        })}
        style={{ maxWidth: inputMaxWidth }}
      >
        <label htmlFor={id} className={styles.label} onClick={onClick}>
          {label}
        </label>
        {/* To enable pseudo css that we can use the :placeholde-shown selector, we need to add a placeholder (empty string is enough) */}

        {'readonly' === type && (
          <div
            ref={ref}
            className={classNames({
              [styles.input]: true,
              [styles.native]: native,
            })}
            onClick={onClick}
          >{value}</div>
        )}

        {'readonly' !== type && (
          <input
            ref={ref}
            className={classNames({
              [styles.input]: true,
              [styles.native]: native,
            })}
            min={min}
            max={max}
            type={type}
            autoFocus={autoFocus}
            id={id}
            name={name}
            value={internalValue}
            autoComplete={autoComplete}
            disabled={disabled}
            placeholder={placeholder}
            onChange={(event): void => {
              const newValue = event.target.value;

              if (onChange) {
                onChange(event, newValue);
              }

              setInternalValue(newValue);
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            onClick={onClick}
            maxLength={maxLength}
          />
        )}

        {isLoading && (
          <div className={styles.loader}>
            <Loader size={16} />
          </div>
        )}

        {isClearable && '' !== internalValue && (
          <span
            className={styles.icon}
            onClick={() => {
              setInternalValue('');
              onClear?.();
            }}
          >
            <Icon size={'0'} icon={'close'} color={'var(--primary-color)'} />
          </span>
        )}

        {suffix && (
          <span
            className={styles.icon}
            onClick={() => {
              switch (suffixAction) {
                case 'focus':
                  if ('readonly' !== type) {
                    ref?.current?.focus();
                  } else {
                    onClick?.();
                  }
                  break;
                case 'clear':
                  setInternalValue('');

                  if (onDelete) {
                    onDelete();
                  }

                  break;
              }
            }}
          >
            {suffix}
          </span>
        )}

        {value !== '' ||
          (internalValue !== '' && (
            <span
              className={styles.deleteIcon}
              onClick={() => {
                setInternalValue('');
                if (onDelete) {
                  onDelete();
                }
              }}
            >
              <Icon icon={'close'} color={themeVariables.black} />
          </span>
          ))}

      </div>

      {(inputError && false === inputError.isValid && true === (props?.form?.hasFormSubmitted ?? true)) && (
        <InputError message={inputError.message} />
      )}
    </div>
  );
});
