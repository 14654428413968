import * as React from 'react';
function SvgLinkedin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M8.956 12.133c.418 0 .765.308.824.71l.009.123v15.478a.833.833 0 01-.71.824l-.123.009h-5.4a.833.833 0 01-.824-.71l-.009-.123V12.966c0-.418.308-.765.71-.824l.123-.009h5.4zm13.588-.011c4.068 0 6.677 3.042 6.796 8.04l.004.36v7.922a.833.833 0 01-.71.824l-.123.009h-5.967a.833.833 0 01-.824-.71l-.009-.123v-8.059a1.546 1.546 0 00-1.341-1.538l-.124-.008-.079.006c-.293 0-.577.083-.821.236l-.141.1-.13.116a1.542 1.542 0 00-.442.918l-.01.174v8.055a.833.833 0 01-.71.824l-.123.009h-5.678a.833.833 0 01-.824-.71l-.009-.123V12.966c0-.418.308-.765.71-.824l.123-.009h5.678c.418 0 .765.308.824.71l.009.123-.001.189.225-.121a8.35 8.35 0 012.869-.861l.432-.037.397-.014zM8.121 13.8H4.388v13.811h3.733V13.8zm14.43-.012l-.352.015a6.682 6.682 0 00-3.871 1.566.834.834 0 01-1.362-.507l-.009-.129-.001-.933h-4.011v13.811h4.011l.001-7.222c0-.681.217-1.34.611-1.885l.156-.199.173-.187a3.216 3.216 0 012.016-.931l.093-.005.038-.007.123-.009a3.203 3.203 0 012.276.946 3.2 3.2 0 01.926 2.025l.009.251v7.222h4.3v-7.088c0-4.101-1.745-6.451-4.595-6.709l-.27-.018-.262-.007zM9.88 7.488l.009.123a3.61 3.61 0 01-6.165 2.553 3.606 3.606 0 01-.783-3.935 3.61 3.61 0 013.069-2.22l.267-.01.26.011a3.614 3.614 0 013.319 3.342l.001.041.021.095zM6.271 5.667l-.226.014a1.948 1.948 0 00-1.675 2.31 1.946 1.946 0 003.524.701c.171-.256.278-.548.314-.851l.005-.086-.004-.02-.009-.123a1.944 1.944 0 00-1.738-1.933l-.191-.011z" />
    </svg>
  );
}
export default SvgLinkedin;
