import { FC, ReactElement } from 'react';
import styles from './hint.module.scss';
import { Icon, IconTypes } from '../icons';
import { Button } from '../../buttons';
import classNames from 'classnames';

/**
 * Usage documentation:
 *
 * @example
 * <Hint>
 *   <HintHeading icon="info-outline" title="Dieses Produkt befindet sich bereits in deinem Warenkorb." />
 *   <HintContent>Du kannst es direkt unten bearbeiten, oder weitere Produkte mieten.</HintContent>
 *   <HintFooter buttonLabel="WEITERE PRODUKTE MIETEN" onButtonClick={() => console.log('Button clicked')} />
 * </Hint>
 */

export type HintHeadingProps = {
  icon: IconTypes;
  title: string;
}

type HintContentProps = {
  children: React.ReactNode;
}

export type HintFooterProps = {
  buttonLabel: string;
  onButtonClick: () => void;
}

type AllowedHintChild = ReactElement<typeof HintHeading> | ReactElement<typeof HintContent> | ReactElement<typeof HintFooter> | false | null;
export type HintProps = {
  children: AllowedHintChild | AllowedHintChild[];
  warn?: boolean;
}

export const HintHeading: FC<HintHeadingProps> = ({ icon, title }) => {
  return <div className={styles.hintHeading}>
    <Icon icon={icon} size={'20px'} color={'var(--color-error)'} />
    <h4 className={styles.headline}>{title}</h4>
  </div>;
};

export const HintContent: FC<HintContentProps> = ({ children }) => {
  return <div className={styles.hintContent}>
    {children}
  </div>;
};

export const HintFooter: FC<HintFooterProps> = ({ buttonLabel, onButtonClick }) => {
  return <div className={styles.hintFooter}>
    <Button ghost={true} bordered={false} small={true} onClick={onButtonClick}>{buttonLabel}</Button>
  </div>;
};

export const Hint: FC<HintProps> = ({ children, warn = false }) => {
  return <div className={classNames({
    [styles.hint]: true,
    [styles.warn]: warn,
  })}>
    {children}
  </div>;
};
