import { FC, useEffect, useState } from "react";
import styles from "./button.module.scss";
import classNames from "classnames";
import { Loader } from '../atoms';
import { IDigandoStylingButtonProps } from "./button";
import { UrlObject } from "url";
import Link from "next/link";

export interface IDigandoLinkButtonProps extends IDigandoStylingButtonProps {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onFocus?: () => void;
  href: UrlObject;
}

let loaderTimeout: NodeJS.Timeout | null = null;

export const LinkButton: FC<IDigandoLinkButtonProps> = (props) => {
  const {
    onClick,
    onFocus,
    spacing = "default",
    small = false,
    bordered = false,
    loading = false,
    fullWidth = false,
    uppercase = true,
  } = props;

  const [showLoader, setShowLoader] = useState(loading);

  useEffect(() => {
    if (loading) {
      setShowLoader(true);
    } else {
      loaderTimeout = setTimeout(() => {
        setShowLoader(false);
      }, 300);
    }

    return () => {
      if (loaderTimeout) {
        clearTimeout(loaderTimeout);
      }
    };
  }, [loading]);

  return (
    <Link
      className={classNames(styles.button, {
        [styles.bordered]: bordered,
        [styles.small]: small,
        [styles.spacingSmall]: spacing === "small",
        [styles.loading]: loading,
        [styles.fullWidth]: fullWidth,
      })}
      onClick={onClick}
      onFocus={onFocus}
      href={props.href}
    >
      <span className={classNames({
        [styles.buttonText]: true,
        [styles.uppercase]: uppercase,
      })}>
        {props.children}
      </span>
      {showLoader && (
        <span className={styles.buttonLoader}>
          <Loader
            invert={!bordered}
            size={20}
          />
        </span>
      )}
    </Link>
  );
};
