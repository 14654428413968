import * as React from 'react';
function SvgEdit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M12.448 19.552a.825.825 0 00.707.236l4.126-.59a.84.84 0 00.47-.236L28.358 8.355c1.3-1.3 1.3-3.414 0-4.714-.628-.629-1.466-.976-2.357-.976s-1.728.347-2.357.976L13.037 14.248a.839.839 0 00-.236.471l-.589 4.126a.836.836 0 00.236.707zm4.321-1.964l-2.75.393.392-2.75 8.642-8.642 2.358 2.357-8.642 8.642zm10.41-10.411l-.59.59-2.358-2.356.59-.59A1.659 1.659 0 0126 4.333c.447 0 .864.172 1.179.487.65.65.65 1.707 0 2.357zM2.667 26.833c0 1.379 1.121 2.5 2.5 2.5h16.667c1.379 0 2.5-1.121 2.5-2.5V18.5a.833.833 0 00-1.666 0v8.333c0 .46-.373.833-.833.833H5.168a.833.833 0 01-.833-.833V10.166c0-.46.373-.833.833-.833h8.333a.833.833 0 000-1.666H5.168a2.502 2.502 0 00-2.5 2.5v16.667z" />
    </svg>
  );
}
export default SvgEdit;
