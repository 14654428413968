import React, { FC, useEffect, useState } from 'react';
import styles from './small-number.module.scss';
import { Icon } from '../atoms';

export interface NumberProps {
  id: string;
  name: string;
  defaultValue?: number;
  onChange: (value: number) => void;
  value?: number;
  min?: number;
  max?: number;
}

export const SmallNumberInput: FC<NumberProps> = (props) => {
  const {
    name,
    defaultValue = 0,
    id,
    onChange,
    value,
    max,
    min,
  } = props;
  const [internalValue, setInternalValue] = useState<number>(defaultValue);

  useEffect(() => {
    if ('number' === typeof value && value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  const handleValueChange = (event: React.MouseEvent<HTMLSpanElement>, newValue: number): void => {
    event.preventDefault();
    event.stopPropagation();

    if ('number' === typeof min && newValue < min) {
      newValue = min;
    }

    if ('number' === typeof max && newValue > max) {
      newValue = max;
    }

    setInternalValue(newValue);
    onChange(newValue);
  }

  return (
    <div className={styles.smallNumber}>
      <Icon icon={'minus'} size={'12px'} onClick={(event) => handleValueChange(event, internalValue - 1)} />
      <input
        name={name}
        id={id}
        type={'number'}
        value={internalValue}
        max={max}
        min={min}
        onChange={(event): void => {
          const newValue = parseInt(event.target.value);

          setInternalValue(newValue);
          onChange(newValue);
        }}
      />
      <Icon icon={'plus'} size={'12px'} onClick={(event) => handleValueChange(event,  internalValue + 1)} />
    </div>
  );
};
