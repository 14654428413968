import * as React from 'react';
function SvgPhone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M15.988 29.358c-2.866 0-5.707-.948-8-2.667a13.25 13.25 0 01-5.2-8.782 13.258 13.258 0 012.533-9.887c2.54-3.387 6.434-5.328 10.683-5.328 2.902 0 5.663.92 7.986 2.662 5.881 4.412 7.078 12.787 2.665 18.669a.836.836 0 01-1.167.166.835.835 0 01-.167-1.167c1.867-2.489 2.652-5.561 2.211-8.65s-2.055-5.818-4.545-7.684A11.555 11.555 0 0016 4.36c-3.719 0-7.127 1.699-9.349 4.662-1.87 2.493-2.657 5.566-2.217 8.651s2.057 5.814 4.549 7.684a11.74 11.74 0 007 2.335c2.419 0 4.168-.608 4.927-1.711.421-.612.529-1.415.319-2.34-.309.069-.62.103-.931.103a4.42 4.42 0 01-2.307-.653.698.698 0 01-.097-.055 33.041 33.041 0 01-8.924-8.922.953.953 0 01-.058-.099 4.404 4.404 0 01.648-5.426l.504-.504a2.028 2.028 0 011.438-.593 2.01 2.01 0 011.439.599L15.05 10.2c.79.791.79 2.078 0 2.869a.363.363 0 000 .512l3.38 3.381a.362.362 0 00.514 0c.382-.382.891-.592 1.433-.592s1.051.21 1.433.592l2.111 2.112c.79.791.79 2.078 0 2.868l-.503.502a4.285 4.285 0 01-.636.524c.42 1.508.25 2.871-.498 3.958-1.095 1.592-3.272 2.433-6.299 2.433v-.002zm2.83-7.72a2.737 2.737 0 003.425-.374l.502-.501c.14-.14.14-.37-.001-.511l-2.111-2.111a.364.364 0 00-.513.001c-.382.382-.891.592-1.434.592s-1.052-.21-1.435-.592l-3.38-3.381a2.032 2.032 0 010-2.868.363.363 0 000-.512L11.76 9.27a.35.35 0 00-.258-.112h-.001a.358.358 0 00-.256.106l-.504.504a2.736 2.736 0 00-.374 3.423l.031.053a31.425 31.425 0 008.363 8.362l.056.032z" />
    </svg>
  );
}
export default SvgPhone;
