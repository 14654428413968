import * as React from 'react';
function SvgWarning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M16 2.667a2.844 2.844 0 012.012.835L28.5 13.989a2.85 2.85 0 010 4.024L18.011 28.501a2.845 2.845 0 01-4.022 0L3.5 18.013a2.85 2.85 0 010-4.024L13.989 3.502a2.852 2.852 0 012.012-.835zm0 17.5a1.243 1.243 0 00-1.154.772 1.248 1.248 0 00.911 1.704 1.255 1.255 0 001.283-.531 1.25 1.25 0 00-1.039-1.944zM16 8.5a.833.833 0 00-.833.833v8.333a.833.833 0 001.666 0V9.333A.833.833 0 0016 8.5z" />
    </svg>
  );
}
export default SvgWarning;
