import React from 'react';
import { IconTypes } from './icon.types';
import styles from './icon.module.scss';
import { Burger, Calendar, CartEmpty, Chat, ChatNew, Check, Close, ContextMenu, Copy, Delete, Dropdown, Edit, Facebook, Faq, Info, InfoOutline, Insta, LeftSlide, LinkArrow, Linkedin, Locate, Location, Logout, MapIcon, MapOff, MapPin, Minus, Money, Partner, Pdf, Phone, Pickup, Plus, Right, RightSlide, Safe, Search, Share, Shield, SortDown, Spinwheel, Time, Twitter, Undo, User, Verfugbarkeit, ViewOn, Warning, Whatsapp, XClose, Xing, Youtube, Zoom, Zustellung } from './generated';

const icons: { [key in IconTypes]: React.ComponentType } = {
  'burger': Burger,
  'calendar': Calendar,
  'cart-empty': CartEmpty,
  'chat': Chat,
  'chat-new': ChatNew,
  'check': Check,
  'close': Close,
  'context-menu': ContextMenu,
  'copy': Copy,
  'delete': Delete,
  'dropdown': Dropdown,
  'edit': Edit,
  'facebook': Facebook,
  'faq': Faq,
  'info': Info,
  'info-outline': InfoOutline,
  'insta': Insta,
  'left-slide': LeftSlide,
  'link-arrow': LinkArrow,
  'linkedin': Linkedin,
  'locate': Locate,
  'location': Location,
  'logout': Logout,
  'map': MapIcon,
  'map-off': MapOff,
  'map-pin': MapPin,
  'minus': Minus,
  'money': Money,
  'pdf': Pdf,
  'phone': Phone,
  'pickup': Pickup,
  'plus': Plus,
  'right': Right,
  'right-slide': RightSlide,
  'safe': Safe,
  'search': Search,
  'share': Share,
  'shield': Shield,
  'sort-down': SortDown,
  'spinwheel': Spinwheel,
  'time': Time,
  'twitter': Twitter,
  'undo': Undo,
  'user': User,
  'Verfugbarkeit': Verfugbarkeit,
  'view-on': ViewOn,
  'warning': Warning,
  'whatsapp': Whatsapp,
  'X-Close': XClose,
  'xing': Xing,
  'youtube': Youtube,
  'zoom': Zoom,
  'zustellung': Zustellung,
  'partner': Partner
};

export const IconComponent: React.FC<{
  icon: IconTypes;
  className?: string;
  color?: string;
  size?: string;
  data?: string;
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
}> = (props) => {
  const {
    color,
    icon: iconName,
    size = 'inherit',
    data = '',
    className,
  } = props;
  const Icon = icons?.[iconName] ?? null;

  if (null === Icon) {
    return null;
  }

  return (
    <span
      style={{
        height: size,
        width: size,
        fontSize: size,
        color: color,
        fill: color,
        lineHeight: size,
      }}
      className={`${styles.icon} icon icon-${iconName} ${className ? className : ''}`}
      data-e2e-id={data}
      onClick={props?.onClick}
    >
      <Icon />
    </span>
  );
};

export const Icon = React.memo(IconComponent);
