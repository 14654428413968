import * as React from 'react';
function SvgXing(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M23.267 3.167a.834.834 0 00-.724.42l-8.678 15.2a.835.835 0 000 .827l5.6 9.8c.148.26.425.42.724.42h5.722a.835.835 0 00.724-1.246l-5.353-9.387 8.442-14.787A.835.835 0 0029 3.167h-5.733zM15.549 19.2l8.202-14.367h3.814l-7.966 13.953a.834.834 0 000 .826l4.878 8.554h-3.804l-5.124-8.967zM5.089 7.511a.834.834 0 00-.731 1.233l3.013 5.511-4.083 6.7A.834.834 0 004 22.222h5.4c.291 0 .56-.151.712-.4l4.333-7.111a.832.832 0 00.019-.834l-3.255-5.933a.832.832 0 00-.731-.432H5.089zm3.976 6.367l-2.57-4.7h3.49l2.786 5.078-3.839 6.3H5.484l3.561-5.844a.835.835 0 00.02-.833z" />
    </svg>
  );
}
export default SvgXing;
