import { FC } from 'react';
import { PageButton } from './page-button';
import styles from './pagination.module.scss';

export type IDigandoPageAllButtonsProps = {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
}

export const PageAllButtons: FC<IDigandoPageAllButtonsProps> = ((props) => {
  const { currentPage, totalPages, setCurrentPage } = props;
  const pages: number[] = new Array(totalPages).fill(0).map((_, i) => i + 1);

  return (
    <div className={styles.buttonsContainer}>
      {pages.map((page) => (
        <PageButton key={page} page={page} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      ))}
    </div>
  );
});
