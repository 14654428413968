import styles from './inputs.module.scss';
import { Icon } from '../atoms';
import { FC } from 'react';

type InputErrorProps = {
  message: string;
}

export const InputError: FC<InputErrorProps> = ({message}) => {
  return (
    <div className={styles.error}>
      <Icon icon={'warning'} size={'16px'} color={'var(--color-error)'} />
      <span>{message}</span>
    </div>
  )
};
