import * as React from 'react';
function SvgXClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M30.414 5.414a2 2 0 10-2.829-2.828L16.499 13.672 5.413 2.586a2 2 0 10-2.828 2.828L13.671 16.5 2.585 27.586a2 2 0 002.828 2.829l11.086-11.086 11.086 11.086a2 2 0 002.829-2.829L19.328 16.5 30.414 5.414z" />
    </svg>
  );
}
export default SvgXClose;
