import { FC } from 'react';
import styles from './tooltip.module.scss';
import React from 'react';

export type TooltipListItem = {
  key: string | React.ReactNode;
  value: string | React.ReactNode;
}

type TooltipListProps = {
  items: TooltipListItem[];
}

export const TooltipList: FC<TooltipListProps> = ({ items }) => {
  return (
    <div className={styles.tooltipList}>
      <div className={styles.tooltipListItem}>
        {items.map((item, index) => (
          <React.Fragment key={`tooltip-list-item-${index}`}>
            <span className={styles.tooltipListItemKey}>{item.key}</span>
            <span className={styles.tooltipListItemDelimiter}>
            <div className={styles.line}></div>
          </span>
            <span className={styles.tooltipListItemValue}>{item.value}</span>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
