import * as React from 'react';
function SvgChat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M13.483 3.774A12.508 12.508 0 0124.33 5.309a12.495 12.495 0 015.472 9.491.833.833 0 11-1.662.116 10.828 10.828 0 00-9.289-9.975 10.834 10.834 0 00-12.294 11.84 10.825 10.825 0 001.578 4.609c.15.241.167.542.045.799l-2.416 5.074 5.073-2.417a.835.835 0 01.798.044c.582.361 1.196.666 1.834.913a.834.834 0 01-.601 1.555 12.662 12.662 0 01-1.717-.816L4.36 29.777a.834.834 0 01-1.111-1.111l3.234-6.792a12.5 12.5 0 017.001-18.099zm3.547 13.28A7.499 7.499 0 1127.636 27.66a7.497 7.497 0 01-12.803-5.303c0-1.989.79-3.897 2.197-5.303zm5.303-.53a5.834 5.834 0 10.001 11.669 5.834 5.834 0 00-.001-11.669zm-3.089 4.411l2.5-2.5a.835.835 0 011.179 0l2.5 2.5a.835.835 0 01-1.179 1.179l-1.077-1.077v4.655a.833.833 0 01-1.666 0v-4.655l-1.077 1.077a.835.835 0 01-1.179-1.179z" />
    </svg>
  );
}
export default SvgChat;
