import { FC, createContext, useContext } from "react";
import {
  useProductConfiguratorValues,
  useSetProductConfigurator,
} from '../../store/recoil/product-configurator';

type RelatedProductConfiguratorContextProps = {
  switchRelatedProduct: (relatedProductUriComponent: string) => void;
};

type RelatedProductConfiguratorWrapperProps = {
  children: React.ReactNode;
};

const RelatedProductConfiguratorContext = createContext<RelatedProductConfiguratorContextProps>({
  switchRelatedProduct: () => console.error('no RelatedProductConfiguratorContext'),
});

export const RelatedProductConfiguratorWrapper: FC<RelatedProductConfiguratorWrapperProps> = ({children}) => {
  const { selectedRelatedProducts, } = useProductConfiguratorValues();
  const { setSelectedRelatedProducts, } = useSetProductConfigurator();

  const switchRelatedProduct = (relatedProductUriComponent: string) => {
    const isSelected = selectedRelatedProducts.find(identifier => identifier === relatedProductUriComponent);

    if (!isSelected) {
      setSelectedRelatedProducts([...selectedRelatedProducts, relatedProductUriComponent]);
    } else {
      setSelectedRelatedProducts(selectedRelatedProducts.filter(identifier => identifier !== relatedProductUriComponent));
    }
  };

  return (
    <RelatedProductConfiguratorContext.Provider value={{
      switchRelatedProduct,
    }}>
      {children}
    </RelatedProductConfiguratorContext.Provider>
  );
}

export function useConfiguratorRelatedProductContext() {
  return useContext(RelatedProductConfiguratorContext);
}
