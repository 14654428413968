import React, { FC, useEffect, useState } from 'react';
import styles from './checkbox.module.scss';
import { InputErrorType, InputValidationRule, UseFormReturn } from './form.hook';
import classNames from 'classnames';

type CheckboxProps = {
  name: string;
  label: string | React.ReactNode
  checked: boolean;
  onChange?: (checked: boolean) => void;
  rules?: InputValidationRule[];
  form?: UseFormReturn;
  disabled?: boolean;
}

type CheckboxListProps = {
  children: React.ReactNode;
}

/**
 * Digandos CheckboxList component.
 *
 * This component is used to wrap multiple Checkbox components and do the styling for the list.
 */
export const CheckboxList: FC<CheckboxListProps> = ({ children }) => {
  return (
    <div className={styles.checkboxList}>{children}</div>
  );
};

/**
 * Digandos Checkbox component.
 *
 * You also can wrap the Checkbox component with CheckboxList component.
 * <CheckboxList>
 *   <Checkbox ... />
 *   <Checkbox ... />
 *   <Checkbox ... />
 *   ...
 * </CheckboxList>
 *
 * @param name
 * @param label The label of the checkbox.
 * @param checked The initial checked state of the checkbox.
 * @param onChange The function that is called when the checkbox is checked or unchecked.
 * @param rules Validation Rules for the checkbox
 * @param form The form context
 * @param disabled True will disable the field
 */
export const Checkbox: FC<CheckboxProps> = ({ name, label, onChange, checked, rules = [], form, disabled = false }) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);
  const [inputError, setInputError] = useState<InputErrorType | null>(null);

  // This effect is used to update the state of the checkbox when the checked prop changes.
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  useEffect(() => {
    if (form) {
      form.validate(name, rules, isChecked);
    }
  }, []);

  return (
    <div className={classNames({
      [styles.checkboxWrapper]: true,
      [styles.hasError]: null !== inputError,
      [styles.isDisabled]: true === disabled,
    })}>
      <label>
        <input
          name={name}
          type='checkbox'
          checked={isChecked}
          disabled={disabled}
          onChange={(event) => {
            const isChecked = event.target.checked;

            setIsChecked(isChecked);

            if (onChange) {
              onChange(event.target.checked);
            }

            if (form) {
              const validationError = form.validate(name, rules, isChecked);

              setInputError(validationError);
            }
          }} />

        <span className={styles.checkbox}>
          <span className={styles.checked} />
        </span>

        <span className={styles.label}>{label}</span>
      </label>
    </div>
  );
};
