import * as React from 'react';
function SvgMapPin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M11.833 25.742c-.319 0-.631-.06-.928-.179l-6.667-2.667a2.487 2.487 0 01-1.572-2.322V4.604a1.645 1.645 0 01.12-.624 1.657 1.657 0 011.547-1.046c.212 0 .421.04.621.121l6.57 2.629a.827.827 0 00.297.059h.024a.813.813 0 00.298-.06l7.094-2.839a2.493 2.493 0 011.857 0l6.667 2.667a2.485 2.485 0 011.572 2.321v4.833a.833.833 0 01-1.666 0V7.832a.83.83 0 00-.523-.774l-6.143-2.457v7.231a.833.833 0 01-1.666 0V4.601L12.764 7.23l-.096.037v16.541l2.677-1.071a.829.829 0 011.083.463.83.83 0 01-.465 1.083l-3.201 1.281a2.49 2.49 0 01-.928.179zm-7.5-5.167a.83.83 0 00.524.774l6.142 2.457V7.266a2.303 2.303 0 01-.096-.036l-6.57-2.629-.001 15.973zM23.5 21.498c-.689 0-1.25-.561-1.25-1.25 0-.637.474-1.164 1.106-1.239a.857.857 0 01.29 0 1.25 1.25 0 011.092 1.092.86.86 0 010 .291 1.253 1.253 0 01-1.082 1.091.83.83 0 01-.156.014z" />
      <path d="M23.499 29.331a1.655 1.655 0 01-1.314-.641c-2.061-2.636-4.519-6.24-4.519-8.442 0-3.217 2.617-5.833 5.833-5.833s5.833 2.616 5.833 5.832c0 2.199-2.458 5.806-4.52 8.444-.318.407-.797.64-1.314.64zM23.5 16.08a4.171 4.171 0 00-4.167 4.167c0 1.29 1.597 4.132 4.167 7.418 2.57-3.288 4.167-6.13 4.167-7.418A4.171 4.171 0 0023.5 16.08z" />
    </svg>
  );
}
export default SvgMapPin;
