import { FC } from 'react';
import styles from './tooltip.module.scss';

type ToolTipProps = {
  children: React.ReactNode;
  tooltipContent: React.ReactNode;
}

export const Tooltip: FC<ToolTipProps> = ({ children, tooltipContent }) => {
  /**
   * Block action when the user clicks on the tooltip
   */
  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  }

  return (
    <div className={styles.tooltip} onClick={onClick}>
      <div className={styles.content}>
        {tooltipContent}
      </div>

      <div className={styles.tooltipIcon}>
        {children}
      </div>
    </div>
  );
};
