import * as React from 'react';
function SvgMoney(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="2 2 28 28"
      {...props}
    >
      <path d="M11.145 13.704a.833.833 0 01.167 1.066l-.08.11-3.037 3.519a5.759 5.759 0 004.205 9.258l.357.011h6.433a5.754 5.754 0 005.164-3.215 5.768 5.768 0 00-.373-5.738l-.2-.279-3.067-3.556a.834.834 0 011.166-1.184l.097.095 3.096 3.592a7.424 7.424 0 01-5.506 11.942l-.377.01h-6.434a7.43 7.43 0 01-6.659-4.146 7.43 7.43 0 01-.727-4.014 7.432 7.432 0 011.303-3.514l.23-.314 3.067-3.556a.833.833 0 011.175-.087zm6.45 1.496a.833.833 0 01-.512 1.061 4.145 4.145 0 00-1.933 1.406l1.658.002a.833.833 0 01.123 1.657l-.123.009h-2.415a4.148 4.148 0 000 1.667h2.416a.833.833 0 01.123 1.657l-.123.009-1.658-.001.095.124a4.14 4.14 0 001.837 1.285.832.832 0 11-.549 1.573 5.805 5.805 0 01-3.326-2.981h-1.399a.833.833 0 01-.123-1.657l.123-.009h.893a5.783 5.783 0 010-1.667l-.893.001a.833.833 0 01-.123-1.657l.123-.009 1.4-.001a5.819 5.819 0 013.326-2.98.833.833 0 011.061.512zm4.213-4.198a.833.833 0 01.123 1.657l-.123.009H10.141a.833.833 0 01-.123-1.657l.123-.009h11.667zm-5.833-8.335a1.669 1.669 0 011.262.579l.124.163.565.847 2.107-1.051c.25-.124.527-.183.803-.173l.207.021a1.663 1.663 0 011.377 1.375c.045.276.021.557-.071.82l-.081.194-1.598 3.196a.833.833 0 01-1.541-.619l.051-.126 1.597-3.195-2.105 1.051a1.663 1.663 0 01-2.029-.428l-.103-.139-.566-.848-.565.847a1.663 1.663 0 01-1.946.646l-.186-.079-2.107-1.051 1.566 3.133a.833.833 0 01-1.421.861l-.07-.116-1.567-3.134c-.156-.312-.21-.665-.154-1.009s.218-.663.463-.91a1.672 1.672 0 011.727-.4l.194.081 2.11 1.052.567-.847a1.67 1.67 0 01.426-.437l.174-.108a1.66 1.66 0 01.786-.197z" />
    </svg>
  );
}
export default SvgMoney;
